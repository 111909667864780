import * as React from 'react';
import {
  CUT_TYPE_TRANSLATIONS,
  CalculatorOrderItem,
  EYELET_INTERVAL_TRANSLATIONS,
  EYELET_TYPES,
  PERFORATION_TYPES,
  PERFORATION_TYPE_TRANSLATIONS,
  PRINTING_TYPE_TRANSLATIONS,
  RIGID_EYELET_TYPE_TRANSLATIONS,
  RIGID_ORIGIN_NAME,
  WHITE_LAYER_TYPES,
  WHITE_LAYER_TYPE_TRANSLATIONS,
} from '@oyp/shared-lib';
import { OrderItemInformationRendererProps } from '../../../../../types';
import { ShortenedName, formatter } from '@oyp/shared-components';

const { formatCentimeter } = formatter;

const RigidOrderItemInformation: React.SFC<OrderItemInformationRendererProps> = props => {
  const { orderItem, materials, laminations } = props;
  const { manufactureData, requestData } = orderItem as CalculatorOrderItem;

  const material = materials.find(m => m.id === requestData.materialId);
  const lamination = laminations.find(l => l.id === requestData.laminationId);

  const {
    eyelet,
    eyeletInterval,
    perforation,
    dimensions,
    cutType,
    whiteLayerType,
    creasing,
    printingType,
    imageryCount,
    quantity,
    passedForPrint,
  } = requestData;

  const { slabCount, slabWidth, slabHeight, multiSlab } = manufactureData.manufacture;

  return (
    <div>
      <div>
        <b>Grand Format - {RIGID_ORIGIN_NAME}</b>
      </div>
      <div>
        Matière : <ShortenedName name={material ? material.name : '-'} maxNameLength={30} />
      </div>
      <div>
        Format : {formatCentimeter(dimensions.width as number)} x{' '}
        {formatCentimeter(dimensions.height as number)}
      </div>
      <div>Quantité : {quantity}</div>
      {imageryCount > 1 ? <div>Amalgame : {imageryCount} visuel(s)</div> : null}
      {multiSlab ? (
        <div>
          Multipanneau : {slabCount} panneaux de {formatCentimeter(slabWidth)} x{' '}
          {formatCentimeter(slabHeight)}
        </div>
      ) : null}
      <div>Découpe : {CUT_TYPE_TRANSLATIONS[cutType]}</div>
      <div>Type d&apos;impression : {PRINTING_TYPE_TRANSLATIONS[printingType]}</div>
      {lamination && lamination.reference && lamination.reference !== '0' ? (
        <div>Lamination : {lamination.name}</div>
      ) : null}
      {eyelet && eyelet !== EYELET_TYPES.NONE ? (
        <div>
          Oeillets : {RIGID_EYELET_TYPE_TRANSLATIONS[eyelet]}{' '}
          {EYELET_INTERVAL_TRANSLATIONS[eyeletInterval]}
        </div>
      ) : null}
      {perforation && perforation !== PERFORATION_TYPES.NONE ? (
        <div>Perforation : {PERFORATION_TYPE_TRANSLATIONS[perforation]}</div>
      ) : null}
      {whiteLayerType && whiteLayerType !== WHITE_LAYER_TYPES.NONE ? (
        <div>Blanc de soutien : {WHITE_LAYER_TYPE_TRANSLATIONS[whiteLayerType]}</div>
      ) : null}
      {creasing ? <div>Rainage</div> : null}
      {passedForPrint && <div>BAT : oui</div>}
    </div>
  );
};

export default RigidOrderItemInformation;
