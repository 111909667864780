import {
  CALCULATORS,
  CUT_TYPES,
  CalculatorFlexibleFormPermanentResources,
  CalculatorRequestData,
  EYELET_TYPES,
  MATERIAL_TYPE_TRANSLATIONS,
  PRINTING_TYPES,
  PRODUCTION_MODES,
  TYPE_FLEXIBLE_PVC,
  TYPE_MAGNETIC,
  TYPE_PAPER,
  TYPE_TAPE,
  TYPE_TARP,
  WHITE_LAYER_TYPES,
} from '@oyp/shared-lib';
import { fetchFlexibleMaterials, fetchLaminations } from '../../actions';
import { getDefaultMaterialForType } from '../../toolbox';
import { getSelectedMaterialType } from '@oyp/shared-components';

export const FLEXIBLE_INITIAL_TYPE = TYPE_TAPE;

export async function fetchFlexiblePermanentResources(): Promise<
  CalculatorFlexibleFormPermanentResources
> {
  const [materials, laminations] = await Promise.all([
    fetchFlexibleMaterials({ sort: 'rank', isActive: true }),
    fetchLaminations({ sort: 'rank', isActive: true }),
  ]);

  const materialTypes = materialBaseTypes.map(type => ({
    ...type,
    isActive: type.type === FLEXIBLE_INITIAL_TYPE,
  }));

  return {
    materials,
    laminations,
    materialTypes,
  };
}

export function getFlexibleInitialRequestData(
  permanentFormResources: CalculatorFlexibleFormPermanentResources
): CalculatorRequestData {
  return {
    ...baseRequestData,
    materialId: getDefaultMaterialForType(
      permanentFormResources.materials,
      getSelectedMaterialType(permanentFormResources.materialTypes)
    ).id,
  };
}

export const baseRequestData: CalculatorRequestData = {
  calculator: CALCULATORS.FLEXIBLE,
  dimensions: {
    width: 0,
    height: 0,
  },
  quantity: 1,
  cutType: CUT_TYPES.STRAIGHT,
  productionMode: PRODUCTION_MODES.STANDARD,
  imageryCount: 1,
  eyelet: EYELET_TYPES.NONE,
  description: '',
  manualReference: '',
  printingType: PRINTING_TYPES.ONE_SIDED,
  whiteLayerType: WHITE_LAYER_TYPES.NONE,
};

const materialBaseTypes = [
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_TAPE], type: TYPE_TAPE, formKey: 'tape' },
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_TARP], type: TYPE_TARP, formKey: 'tarp' },
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_PAPER], type: TYPE_PAPER, formKey: 'paper' },
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_FLEXIBLE_PVC], type: TYPE_FLEXIBLE_PVC, formKey: 'pvc' },
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_MAGNETIC], type: TYPE_MAGNETIC, formKey: 'magnetic' },
];
