"use strict";

var _defineProperty = require("/var/www/vhosts/preprod.oyp.fr/deploy_tools/apps/demo-calm-panda/releases/20210420162956/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SmartLabel;

(function (SmartLabel) {
  var _SmartLabel$APPLICATI, _SmartLabel$MATERIAL_, _SmartLabel$FINISH_TR;

  var MATERIALS;

  (function (MATERIALS) {
    MATERIALS["COUCHE_PERMANENT"] = "SMART_LABEL_MATERIAL_COUCHE_PERMANENT";
    MATERIALS["COUCHE_ENLEVABLE"] = "SMART_LABEL_MATERIAL_COUCHE_ENLEVABLE";
    MATERIALS["PP_ARGENT"] = "SMART_LABEL_MATERIAL_PP_ARGENT";
    MATERIALS["PP_BLANC"] = "SMART_LABEL_MATERIAL_PP_BLANC";
    MATERIALS["PP_BLANC_ALIMENTAIRE"] = "SMART_LABEL_MATERIAL_PP_BLANC_ALIMENTAIRE";
    MATERIALS["PP_TRANSPARENT"] = "SMART_LABEL_MATERIAL_PP_TRANSPARENT";
    MATERIALS["PP_TRANSPARENT_VITRO"] = "SMART_LABEL_MATERIAL_PP_TRANSPARENT_VITRO"; // Papier non-couché aspect naturel 95g

    MATERIALS["TINTORETTO"] = "SMART_LABEL_MATERIAL_TINTORETTO";
    MATERIALS["JADERASTER"] = "SMART_LABEL_MATERIAL_JADERASTER";
    MATERIALS["STICKER"] = "SMART_LABEL_MATERIAL_STICKER";
    MATERIALS["PLANCHE_A4"] = "SMART_LABEL_MATERIAL_PLANCHE_A4";
    MATERIALS["KRAFT"] = "SMART_LABEL_MATERIAL_KRAFT";
    MATERIALS["FLUO_JAUNE"] = "SMART_LABEL_MATERIAL_FLUO_JAUNE";
    MATERIALS["FLUO_ORANGE"] = "SMART_LABEL_MATERIAL_FLUO_ORANGE";
    MATERIALS["FLUO_VERT"] = "SMART_LABEL_MATERIAL_FLUO_VERT";
    MATERIALS["CANNE_FIBER"] = "SMART_LABEL_MATERIAL_CANNE_FIBER";
    MATERIALS["PP_EPAIS"] = "SMART_LABEL_MATERIAL_PP_EPAIS";
  })(MATERIALS = SmartLabel.MATERIALS || (SmartLabel.MATERIALS = {}));

  var FINISHES;

  (function (FINISHES) {
    FINISHES["NONE"] = "SMART_LABEL_FINISH_NONE";
    FINISHES["PELLICULAGE_BRILLANT"] = "SMART_LABEL_FINISH_PELLICULAGE_BRILLANT";
    FINISHES["PELLICULAGE_MAT"] = "SMART_LABEL_FINISH_PELLICULAGE_MAT";
    FINISHES["PELLICULAGE_SOFT_TOUCH"] = "SMART_LABEL_FINISH_PELLICULAGE_SOFT_TOUCH";
    FINISHES["VERNIS_BRILLANT"] = "SMART_LABEL_FINISH_VERNIS_BRILLANT";
    FINISHES["VERNIS_MAT"] = "SMART_LABEL_FINISH_VERNIS_MAT";
    FINISHES["A5_NONE"] = "SMART_LABEL_FINISH_A5_NONE";
    FINISHES["A5_PELLICULAGE_BRILLANT"] = "SMART_LABEL_FINISH_A5_PELLICULAGE_BRILLANT";
    FINISHES["A5_PELLICULAGE_MAT"] = "SMART_LABEL_FINISH_A5_PELLICULAGE_MAT";
    FINISHES["A4_VERNIS_BRILLANT"] = "SMART_LABEL_FINISH_A4_VERNIS_BRILLANT";
    FINISHES["A5_VERNIS_BRILLANT"] = "SMART_LABEL_FINISH_A5_VERNIS_BRILLANT";
  })(FINISHES = SmartLabel.FINISHES || (SmartLabel.FINISHES = {}));

  var COLOR_MODES;

  (function (COLOR_MODES) {
    COLOR_MODES["NONE"] = "SMART_LABEL_COLOR_MODE_NONE";
    COLOR_MODES["CMJN"] = "SMART_LABEL_COLOR_MODE_CMJN";
    COLOR_MODES["CMJNW"] = "SMART_LABEL_COLOR_MODE_CMJNW";
    COLOR_MODES["W"] = "SMART_LABEL_COLOR_MODE_W";
    COLOR_MODES["NOIR"] = "SMART_LABEL_COLOR_MODE_NOIR";
  })(COLOR_MODES = SmartLabel.COLOR_MODES || (SmartLabel.COLOR_MODES = {}));

  var APPLICATIONS;

  (function (APPLICATIONS) {
    APPLICATIONS["MANUAL"] = "0";
    APPLICATIONS["AUTOMATIC"] = "1";
  })(APPLICATIONS = SmartLabel.APPLICATIONS || (SmartLabel.APPLICATIONS = {}));

  var CORE_SIZES;

  (function (CORE_SIZES) {
    CORE_SIZES["SIZE_40"] = "40";
    CORE_SIZES["SIZE_76"] = "76";
  })(CORE_SIZES = SmartLabel.CORE_SIZES || (SmartLabel.CORE_SIZES = {}));

  var ORIENTATIONS;

  (function (ORIENTATIONS) {
    ORIENTATIONS["ORIENTATION_0"] = "0";
    ORIENTATIONS["ORIENTATION_90"] = "90";
    ORIENTATIONS["ORIENTATION_180"] = "180";
    ORIENTATIONS["ORIENTATION_270"] = "270";
  })(ORIENTATIONS = SmartLabel.ORIENTATIONS || (SmartLabel.ORIENTATIONS = {})); // Traductions


  SmartLabel.APPLICATION_TRANSLATIONS = (_SmartLabel$APPLICATI = {}, _defineProperty(_SmartLabel$APPLICATI, APPLICATIONS.AUTOMATIC, 'Automatique'), _defineProperty(_SmartLabel$APPLICATI, APPLICATIONS.MANUAL, 'Manuelle'), _SmartLabel$APPLICATI);
  SmartLabel.MATERIAL_TRANSLATIONS = (_SmartLabel$MATERIAL_ = {}, _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.COUCHE_PERMANENT, 'Papier couché 80g - permanent'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.COUCHE_ENLEVABLE, 'Papier couché 80g - enlevable'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_ARGENT, 'Polypro metal argent 50μ'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_BLANC, 'Polypro blanc 60μ'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_BLANC_ALIMENTAIRE, 'Polypro blanc alimentaire'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_TRANSPARENT, 'Polypro transparent 60μ'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_TRANSPARENT_VITRO, 'Polypro transparent vitrophanie 60μ'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.TINTORETTO, 'Papier non-couché aspect naturel 95g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.JADERASTER, 'Papier perlé-gaufré 90g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.STICKER, ''), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PLANCHE_A4, ''), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.KRAFT, 'Papier kraft 70g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.FLUO_JAUNE, 'Papier jaune fluo 80g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.FLUO_ORANGE, 'Papier orange fluo 80g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.FLUO_VERT, 'Papier vert fluo 80g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.CANNE_FIBER, 'Papier fibre de canne à sucre 90g'), _defineProperty(_SmartLabel$MATERIAL_, MATERIALS.PP_EPAIS, ''), _SmartLabel$MATERIAL_);

  function translateColorMode(material, colorMode) {
    switch (colorMode) {
      case COLOR_MODES.NONE:
        return 'Sans';

      case COLOR_MODES.NOIR:
        return 'Noir';

      case COLOR_MODES.W:
        return 'Blanc seul';

      case COLOR_MODES.CMJN:
        return material === MATERIALS.PP_TRANSPARENT_VITRO ? 'Vitrophanie quadri' : 'Quadri';

      case COLOR_MODES.CMJNW:
        return material === MATERIALS.PP_TRANSPARENT_VITRO ? 'Vitrophanie quadri + blanc' : 'Quadri + blanc';
    }
  }

  SmartLabel.translateColorMode = translateColorMode;
  SmartLabel.FINISH_TRANSLATIONS = (_SmartLabel$FINISH_TR = {}, _defineProperty(_SmartLabel$FINISH_TR, FINISHES.NONE, 'Sans'), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.PELLICULAGE_BRILLANT, 'Pelliculage brillant'), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.PELLICULAGE_MAT, 'Pelliculage Mat'), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.PELLICULAGE_SOFT_TOUCH, ''), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.VERNIS_BRILLANT, 'Vernis Brillant'), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.VERNIS_MAT, 'Vernis mat'), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.A5_NONE, ''), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.A5_PELLICULAGE_BRILLANT, ''), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.A5_PELLICULAGE_MAT, ''), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.A4_VERNIS_BRILLANT, ''), _defineProperty(_SmartLabel$FINISH_TR, FINISHES.A5_VERNIS_BRILLANT, ''), _SmartLabel$FINISH_TR);

  function getMaterialCompatibleColorModes(material) {
    switch (material) {
      case MATERIALS.COUCHE_PERMANENT:
        return [COLOR_MODES.CMJN];

      case MATERIALS.COUCHE_ENLEVABLE:
        return [COLOR_MODES.CMJN];

      case MATERIALS.PP_ARGENT:
        return [COLOR_MODES.CMJNW];

      case MATERIALS.PP_BLANC:
        return [COLOR_MODES.CMJN];

      case MATERIALS.PP_BLANC_ALIMENTAIRE:
        return [COLOR_MODES.CMJN];

      case MATERIALS.PP_TRANSPARENT:
        return [COLOR_MODES.CMJNW];

      case MATERIALS.PP_TRANSPARENT_VITRO:
        return [COLOR_MODES.CMJN, COLOR_MODES.CMJNW];

      case MATERIALS.TINTORETTO:
        return [COLOR_MODES.CMJN];

      case MATERIALS.JADERASTER:
        return [COLOR_MODES.CMJN];

      case MATERIALS.STICKER:
        return [COLOR_MODES.NONE];

      case MATERIALS.PLANCHE_A4:
        return [COLOR_MODES.NONE];

      case MATERIALS.KRAFT:
        return [COLOR_MODES.CMJNW];

      case MATERIALS.FLUO_JAUNE:
        return [COLOR_MODES.NOIR];

      case MATERIALS.FLUO_ORANGE:
        return [COLOR_MODES.NOIR];

      case MATERIALS.FLUO_VERT:
        return [COLOR_MODES.NOIR];

      case MATERIALS.CANNE_FIBER:
        return [COLOR_MODES.CMJN];

      case MATERIALS.PP_EPAIS:
        return [COLOR_MODES.CMJN];
    }
  }

  SmartLabel.getMaterialCompatibleColorModes = getMaterialCompatibleColorModes;

  function getMaterialCompatibleFinishes(material) {
    switch (material) {
      case MATERIALS.COUCHE_PERMANENT:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT, FINISHES.VERNIS_BRILLANT, FINISHES.VERNIS_MAT];

      case MATERIALS.COUCHE_ENLEVABLE:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT, FINISHES.VERNIS_BRILLANT];

      case MATERIALS.PP_ARGENT:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT, FINISHES.VERNIS_BRILLANT];

      case MATERIALS.PP_BLANC:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT, FINISHES.VERNIS_BRILLANT, FINISHES.VERNIS_MAT];

      case MATERIALS.PP_BLANC_ALIMENTAIRE:
        return [FINISHES.NONE];

      case MATERIALS.PP_TRANSPARENT:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT, FINISHES.VERNIS_BRILLANT];

      case MATERIALS.PP_TRANSPARENT_VITRO:
        return [FINISHES.VERNIS_BRILLANT];

      case MATERIALS.TINTORETTO:
        return [FINISHES.NONE];

      case MATERIALS.JADERASTER:
        return [FINISHES.VERNIS_BRILLANT];

      case MATERIALS.STICKER:
        return [FINISHES.NONE];

      case MATERIALS.PLANCHE_A4:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT];

      case MATERIALS.KRAFT:
        return [FINISHES.NONE];

      case MATERIALS.FLUO_JAUNE:
        return [FINISHES.NONE];

      case MATERIALS.FLUO_ORANGE:
        return [FINISHES.NONE];

      case MATERIALS.FLUO_VERT:
        return [FINISHES.NONE];

      case MATERIALS.CANNE_FIBER:
        return [FINISHES.NONE];

      case MATERIALS.PP_EPAIS:
        return [FINISHES.PELLICULAGE_BRILLANT, FINISHES.PELLICULAGE_MAT];
    }
  }

  SmartLabel.getMaterialCompatibleFinishes = getMaterialCompatibleFinishes;
})(SmartLabel = exports.SmartLabel || (exports.SmartLabel = {}));