import './ListContainer.scss';
import * as React from 'react';
import { Card, WindowInfiniteLoader } from '@oyp/shared-components';
import {
  ModelOrder,
  ModelOrderItem,
  ModelPayment,
  ModelShippingAddress,
  ModelUser,
  ORDER_ITEM_STATUSES,
  ORDER_SCOPE_ORDER_ONLY,
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_PENDING_PAYMENT_VALIDATION,
  ORDER_STATUS_SHIPPED,
  QUOTE_STATUS_CREATED,
  mapId,
} from '@oyp/shared-lib';
import { ORDER_STATUS_LABELS, filterOrderPrefix } from '../utils';
import { RouteComponentProps } from 'react-router';
import { UserContext, UserContextInterface } from '../../../UserContext';
import { fetchOrderItemsForOrder } from '../actions/order_item';
import { fetchOrdersWithProprietaryEntities, saveOneOrder } from '../actions/order';
import { fetchResellerUsersForReseller } from '../actions/reseller';
import { getUserDisplayName } from '@oyp/shared-components/dist/lib/utils/names';
import { toast } from 'mdbreact';
import DebouncedInputGroup from '../../../components/DebouncedInputGroup';
import LoaderRow from '../../../components/list/LoaderRow';
import OrderListRow from '../components/OrderListRow';
import OrderShippingDetails from '../_modules/shipping-details/OrderShippingDetails';
import SelectGroup, { SelectOption } from '../../../components/form/SelectGroup';
import SideNavigationMenu, { NAVIGATION_LINK_IDS } from '../../../components/SideNavigationMenu';

interface OrderListFilter {
  orderReference: string;
  page: number;
  count: number;
  sort?: string;
  creatorId?: string;
  search?: string;
  shippingAddressSearch?: string;
  status?: string;
}

interface ListContainerState {
  orders: ModelOrderWithSubEntities[];
  isLoading: boolean;
  filters: OrderListFilter;
  resellerUsers: ModelUser[];
  usersOptions: any[];
  statusOptions: any[];
  orderToShow: ModelOrderWithSubEntities | void;
  userIds: any[];
  listMeta: any;
}

interface ModelOrderWithSubEntities extends ModelOrder {
  orderItems: ModelOrderItem[];
  shippingAddress?: ModelShippingAddress;
  creator?: ModelUser;
  payment?: ModelPayment;
}

class ListContainer extends React.Component<RouteComponentProps> {
  state: ListContainerState = {
    orders: [],
    isLoading: true,
    userIds: [],
    filters: {
      orderReference: '',
      count: 20,
      page: 0,
      sort: '-orderReference',
    },
    resellerUsers: [],
    usersOptions: [],
    statusOptions: [],
    orderToShow: null,
    listMeta: {},
  };

  context: UserContextInterface;
  constructor(props: RouteComponentProps, context: UserContextInterface) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleLoadMoreItems = this.handleLoadMoreItems.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleEditOrder = this.handleEditOrder.bind(this);

    loadInitialData(this, context);
  }

  render() {
    const { isLoading, orders, filters, usersOptions, statusOptions, orderToShow } = this.state;

    return (
      <div>
        <OrderShippingDetails currentOrder={orderToShow} onClose={this.closeModal} />

        <div className="reseller-app-page-title">MON COMPTE</div>
        <div className="user-container orders-list-container">
          <div className="row ml-0 mr-0">
            <div className="col-md-3 laptop-padding-right">
              <SideNavigationMenu activeLink={NAVIGATION_LINK_IDS.ORDERS_LIST} />
            </div>
            <div className="col-md-9 laptop-padding-left">
              <Card className=" main-card orders-list-container" title={'MES COMMANDES'}>
                <div className="list-filters-container">
                  <div className="row ml-0 mr-0">
                    <div className="col-sm-12">
                      <div className="form-row m-0">
                        <DebouncedInputGroup
                          name="search"
                          label="Numéro ou référence"
                          hint="Numéro ou référence de ma commande"
                          s={3}
                          value={filters.search}
                          handleChange={search => this.loadOrders({ search })}
                        />
                        <SelectGroup
                          s={3}
                          placeholder="Rechercher"
                          noResultsText="Aucun résultat"
                          onChange={(selectedOption: SelectOption<string>) =>
                            this.handleFilterChange(selectedOption, 'creatorId')
                          }
                          options={usersOptions}
                          clearable={true}
                          searchable={true}
                          value={filters.creatorId}
                          label="Créateur"
                        />
                        <SelectGroup
                          s={3}
                          placeholder="Rechercher"
                          noResultsText="Aucun résultat"
                          onChange={(selectedOption: SelectOption<string>) =>
                            this.handleFilterChange(selectedOption, 'status')
                          }
                          options={statusOptions}
                          clearable={true}
                          searchable={true}
                          value={filters.status}
                          label="Statut"
                        />
                        <DebouncedInputGroup
                          name="shippingAddressSearch"
                          label="Destinataire / Adresse de livraison"
                          hint="Rechercher dans une adresse"
                          s={3}
                          value={filters.shippingAddressSearch}
                          handleChange={shippingAddressSearch =>
                            this.loadOrders({ shippingAddressSearch })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="result-container">
                  <table className="table table-borderless table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th className="bg-primary-lighter text-left font-weight-bold text-white" />
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                          scope="col"
                        >
                          Numéro
                        </th>
                        <th className="bg-primary-lighter text-left font-weight-bold text-white">
                          Référence
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                          scope="col"
                        >
                          Destinataire
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white order-list-delivery"
                          scope="col"
                        >
                          Livraison prévue le{' '}
                          <i
                            onClick={() => this.sortList('deliveryDate')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                          scope="col"
                        >
                          Statut
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white order-list-price"
                          scope="col"
                        >
                          Total TTC{' '}
                          <i
                            onClick={() => this.sortList('taxIncludedTotal')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white order-list-creation"
                          scope="col"
                        >
                          Date de création{' '}
                          <i
                            onClick={() => this.sortList('createdAt')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                          scope="col"
                        >
                          Créateur
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((order, key) => (
                        <OrderListRow
                          order={order}
                          orderItems={order.orderItems}
                          key={key}
                          onTrackingDisplay={() => this.showOrderTracking(order)}
                          onCancelOrder={this.handleCancelOrder}
                          onEditOrder={this.handleEditOrder}
                        />
                      ))}
                      {isLoading ? <LoaderRow /> : null}
                    </tbody>
                  </table>
                  <WindowInfiniteLoader
                    isLoading={isLoading}
                    onLoadMore={this.handleLoadMoreItems}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleLoadMoreItems() {
    const { listMeta, orders, filters } = this.state;

    return listMeta.totalCount > orders.length
      ? this.loadOrders({ page: filters.page + 1 }, true)
      : null;
  }

  async sortList(name: string) {
    const prefix = filterOrderPrefix(this.state.filters, name);
    await this.loadOrders({ sort: prefix + name });
  }

  async loadOrders(filterSelection: Partial<OrderListFilter> = {}, addToList = false) {
    const filters = {
      ...this.state.filters,
      ...filterSelection,
      page: addToList ? filterSelection.page : 0,
    };

    this.setState({
      isLoading: true,
      filters,
    });

    const orders = await fetchOrdersWithProprietaryEntities({
      ...filters,
      scope: ORDER_SCOPE_ORDER_ONLY,
    });

    const ordersWithSubEntities: ModelOrderWithSubEntities[] = await Promise.all(
      orders.orders.map(async order => {
        const orderItems = await fetchOrderItemsForOrder(order.id);

        return {
          ...order,
          orderItems,
        };
      })
    );

    this.setState({
      listMeta: orders.meta,
      orders: addToList ? [...this.state.orders, ...ordersWithSubEntities] : ordersWithSubEntities,
      filters,
      isLoading: false,
    });

    return ordersWithSubEntities;
  }

  showOrderTracking(order: ModelOrder) {
    this.setState({
      ...this.state,
      orderToShow: order,
    });
  }

  closeModal() {
    this.setState({
      ...this.state,
      orderToShow: null,
    });
  }

  async handleFilterChange(event: SelectOption<string>, label: string, defaultValue?: string) {
    if (event === undefined) {
      await this.loadOrders({ [label]: defaultValue });
    } else {
      await this.loadOrders({ [label]: event.value });
    }
  }

  async handleCancelOrder(order: ModelOrder) {
    await saveOneOrder({ ...order, isDisabled: true });
    toast.success(`Commande annulée`, 1000);
    await this.loadOrders();
  }

  async handleEditOrder(order: ModelOrder) {
    await saveOneOrder({ ...order, status: QUOTE_STATUS_CREATED });
    this.props.history.push(`/quote/${order.id}`);
  }
}

ListContainer.contextType = UserContext;

export default ListContainer;

async function loadInitialData(container: any, context: UserContextInterface) {
  const { reseller } = context;

  const users = await fetchResellerUsersForReseller(reseller.id);
  const userIds = users.map(mapId);

  await container.loadOrders();

  container.setState({
    isLoading: false,
    userIds,
    resellerUsers: users,

    usersOptions: users.map(user => ({
      value: user.id,
      label: getUserDisplayName(user),
    })),

    statusOptions: [
      {
        value: ORDER_STATUS_PENDING_PAYMENT_VALIDATION,
        label: `Payment en cours ou refusé`,
      },
      {
        value: ORDER_STATUS_CONFIRMED,
        label: ORDER_STATUS_LABELS[ORDER_STATUS_CONFIRMED],
      },
      {
        value: ORDER_STATUS_SHIPPED,
        label: ORDER_STATUS_LABELS[ORDER_STATUS_SHIPPED],
      },
      {
        value: ORDER_ITEM_STATUSES.FILES_REFUSED,
        label: ORDER_STATUS_LABELS[ORDER_ITEM_STATUSES.FILES_REFUSED],
      },
    ],
  });
}
