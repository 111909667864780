"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var ConfirmationButton_1 = require("../ConfirmationButton");

var TooltipContainer_1 = require("../TooltipContainer");

exports.UploadingFileItem = function (_ref) {
  var file = _ref.file;
  var percentComplete = isNaN(file.percentComplete) ? 0 : Math.round(file.percentComplete * 100);
  return React.createElement("div", {
    className: "file-item-wrapper uploading-file"
  }, React.createElement(TooltipContainer_1.TooltipContainer, {
    tooltipContent: file.name
  }, React.createElement("div", {
    className: "title"
  }, truncateFileNameIfNeeded(file.name))), React.createElement("div", {
    className: "placeholder"
  }, React.createElement("p", null, "Envoi en cours"), React.createElement("p", null, percentComplete, " %"), React.createElement("div", {
    className: "progress"
  }, React.createElement("div", {
    className: "determinate",
    style: {
      width: "".concat(percentComplete, "%")
    }
  }))), React.createElement(ConfirmationButton_1.ConfirmationButton, {
    iconName: "clear",
    parameters: file,
    ownerId: file.xhrRequest,
    onConfirmation: function onConfirmation() {
      return file.xhrRequest.abort();
    }
  }));
};

var truncateFileNameIfNeeded = function truncateFileNameIfNeeded(name) {
  if (name.length < 15) {
    return name;
  }

  var splitName = name.split('.');
  var extension = splitName[splitName.length - 1];
  var firstSegment = splitName.slice(0, splitName.length - 1).join('.');
  return "".concat(firstSegment.substr(0, 12), "[...].").concat(extension);
};