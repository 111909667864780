"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var shared_lib_1 = require("@oyp/shared-lib");

function formatPrice() {
  var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat(Number(price).toFixed(2), " \u20AC");
}

exports.formatPrice = formatPrice;

function formatPercentage() {
  var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat(Number(price).toFixed(2), " %");
}

exports.formatPercentage = formatPercentage;

var getFormattedDayMonth = function getFormattedDayMonth(date) {
  var day = "".concat(date.getDate()).padStart(2, '0');
  var month = "".concat(date.getMonth() + 1).padStart(2, '0');
  return {
    day: day,
    month: month
  };
};

function formatDateStringToShortText(dateString) {
  if (!dateString) {
    return '';
  }

  var date = new Date(dateString);
  return shared_lib_1.formatDateToDmy(date);
}

exports.formatDateStringToShortText = formatDateStringToShortText;

function formatDateTimeStringToNumericText(dateString) {
  if (!dateString) {
    return '';
  }

  return "Le ".concat(formatDateStringToDayMonth(dateString), " \xE0 ").concat(formatTimeStringToHourMinute(dateString));
}

exports.formatDateTimeStringToNumericText = formatDateTimeStringToNumericText;

function formatDateStringToDayMonth(dateString) {
  if (!dateString) {
    return '';
  }

  var date = new Date(dateString);

  var _getFormattedDayMonth = getFormattedDayMonth(date),
      day = _getFormattedDayMonth.day,
      month = _getFormattedDayMonth.month;

  return "".concat(day, "/").concat(month);
}

exports.formatDateStringToDayMonth = formatDateStringToDayMonth;

function formatDateStringToFullText(dateValue) {
  if (!dateValue) {
    return '';
  }

  var date = new Date(dateValue);
  var options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };
  return date.toLocaleDateString('fr-FR', options);
}

exports.formatDateStringToFullText = formatDateStringToFullText;

function formatDeliveryDateString(dateValue) {
  if (!dateValue) {
    return '';
  }

  var date = new Date(dateValue);
  var options = {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric'
  };
  return date.toLocaleDateString('fr-FR', options);
}

exports.formatDeliveryDateString = formatDeliveryDateString;

function formatDateTimeStringToShortText() {
  var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!dateString) {
    return '';
  }

  var date = new Date(dateString);
  var options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric'
  };
  return date.toLocaleDateString('fr-FR', options);
}

exports.formatDateTimeStringToShortText = formatDateTimeStringToShortText;

function formatTimeStringToHourMinute() {
  var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!dateString) {
    return '';
  }

  var date = new Date(dateString);
  var hour = "".concat(date.getHours()).padStart(2, '0');
  var minute = "".concat(date.getMinutes()).padStart(2, '0');
  return "".concat(hour, ":").concat(minute);
}

exports.formatTimeStringToHourMinute = formatTimeStringToHourMinute;

function formatTimeToHourMinute(date) {
  var hour = "".concat(date.getHours()).padStart(2, '0');
  var minute = "".concat(date.getMinutes()).padStart(2, '0');
  var seconds = "".concat(date.getSeconds()).padStart(2, '0');
  return "".concat(hour, ":").concat(minute, ":").concat(seconds);
}

exports.formatTimeToHourMinute = formatTimeToHourMinute;

function formatWeight() {
  var weight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat(Number(weight).toFixed(2), " Kg");
}

exports.formatWeight = formatWeight;

function formatCentimeter() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat(Number(length).toFixed(2), " cm");
}

exports.formatCentimeter = formatCentimeter;

function formatMeter() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat((Number(length) / 100).toFixed(2), " m");
}

exports.formatMeter = formatMeter;

function formatSquareMeter() {
  var area = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat((Number(area) / 10000).toFixed(2), " m\xB2");
}

exports.formatSquareMeter = formatSquareMeter;

function formatPriceBySquareMeter() {
  var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "".concat(Number(price).toFixed(2), " \u20AC/m\xB2");
}

exports.formatPriceBySquareMeter = formatPriceBySquareMeter;

exports.truncateString = function (inputString, maxLength) {
  if (inputString.length < maxLength) {
    return inputString;
  }

  return "".concat(inputString.substr(0, maxLength - 5), "[...]");
};

exports.toClosestLengthUnit = function (length) {
  if (length > 1000) {
    return "".concat(length / 1000, "m");
  }

  return "".concat(length, "cm");
};

exports.formatPhoneNumberView = function (phoneNumber) {
  return phoneNumber ? phoneNumber.getRegionCode() === 'FR' ? phoneNumber.getNumber('national') : phoneNumber.getNumber('international') : '';
};

exports.formatPhoneNumberEdit = function (phoneNumber) {
  return phoneNumber ? phoneNumber.getRegionCode() === 'FR' ? "0".concat(phoneNumber.getNumber('significant')) : phoneNumber.getNumber() : '';
};

function capitalizeFirstLetter(str) {
  if (str.length < 1) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

exports.capitalizeFirstLetter = capitalizeFirstLetter;

function formatMinuteAmount(amount) {
  var days = Math.floor(amount / (60 * 24));
  var hours = Math.floor((amount - days * 60 * 24) / 60);
  var minutes = Math.round(amount % 60);
  return "".concat(days ? "".concat(days, "j ") : '').concat(hours ? "".concat(hours, "h ") : '').concat(minutes, "m");
}

exports.formatMinuteAmount = formatMinuteAmount;