"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.isCalculatorOrderItem = function (oI) {
  return oI.orderItemType === types_1.ORDER_ITEM_TYPES.CALCULATOR;
};

exports.isSaxoprintOrderItem = function (oI) {
  return oI.orderItemType === types_1.ORDER_ITEM_TYPES.SAXOPRINT;
};

exports.isSmartLabelOrderItem = function (oI) {
  return oI.orderItemType === types_1.ORDER_ITEM_TYPES.SMART_LABEL;
};