import { PRIMARY_COLOR, PRIMARY_COLOR_DARKER, SECONDARY_COLOR_DARKER } from '../../style_variables';
import { useOnClickOutside, useOnKeyPress } from '@oyp/shared-components';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

export interface SelectOption<T> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface BaseSelectProps<T> {
  options: SelectOption<T>[];
  label?: string | JSX.Element;
  name?: string;
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string | JSX.Element;
  className?: string;
  s?: number;
  m?: number;
  l?: number;
  error?: string;
  warning?: string;
  success?: string;
  alert?: InputAlert;
  renderWrapper?: () => void;
  noResultsText?: string;
}

interface SelectPropsSolo<T> extends BaseSelectProps<T> {
  multi?: false;
  searchable?: boolean;
  value: T;
  clearable: boolean;
  onChange: (option: SelectOption<T> | undefined, event?: any) => void;
}

interface SelectPropsMulti<T> extends BaseSelectProps<T> {
  multi: true;
  value: T[];
  onChange: (options: SelectOption<T>[], event?: any) => void;
}

interface InputAlert {
  kind: 'error' | 'warning' | 'success';
  content: string;
}

type SelectGroupProps<T> = SelectPropsSolo<T> | SelectPropsMulti<T>;

function SelectGroup<T>(props: SelectGroupProps<T>) {
  const { className = '', label, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const groupClass = getClassStringFromArray([className, ...getResponsiveClasses(props)]);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <StyledFormGroup
      className={`${groupClass}`}
      ref={ref}
      disabled={disabled}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
    >
      {label && <StyledLabel isOpen={isOpen}>{label}</StyledLabel>}
      {isSoloProps(props) ? (
        <SoloSelect
          {...props}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      ) : (
        <MultiSelect {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </StyledFormGroup>
  );
}

function SoloSelect<T>(
  props: SelectPropsSolo<T> & {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    searchValue: string;
    setSearchValue: (val: string) => void;
  }
) {
  const {
    value,
    options,
    onChange,
    isOpen,
    setIsOpen,
    placeholder,
    searchable,
    clearable,
    searchValue,
    setSearchValue,
    noResultsText,
  } = props;
  const selectedOption = options.find(option => option.value === value);
  const filteredOption = options.filter(option =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );
  useOnKeyPress('ArrowUp', [value, isOpen], () => {
    const currentOptionIndex = options.findIndex(option => option.value === value);
    if (!isOpen || currentOptionIndex < 1) {
      return;
    }
    const newOptionIndex = currentOptionIndex - 1;
    onChange(options[newOptionIndex]);
  });
  useOnKeyPress('ArrowDown', [value, isOpen], () => {
    const currentOptionIndex = options.findIndex(option => option.value === value);
    if (!isOpen || currentOptionIndex >= options.length - 1) {
      return;
    }
    const newOptionIndex = currentOptionIndex + 1;
    onChange(options[newOptionIndex]);
  });
  useOnKeyPress('Enter', [isOpen], () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  });

  const placeholderValue = selectedOption ? selectedOption.label : placeholder;

  return (
    <>
      <StyledCurrentOption onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {searchable ? (
          <StyledInput
            placeholder={placeholderValue}
            selectedOption={selectedOption}
            onChange={event => setSearchValue(event.target.value)}
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            clearable={clearable}
            value={searchValue}
            onBlur={() => setSearchValue('')}
          />
        ) : selectedOption ? (
          selectedOption.label
        ) : (
          placeholder
        )}
        {clearable && selectedOption && (
          <StyledSelectClearButton className="material-icons" onClick={() => onChange(undefined)}>
            clear
          </StyledSelectClearButton>
        )}
        <SelectArrowIcon className="material-icons">
          {' '}
          {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </SelectArrowIcon>
      </StyledCurrentOption>{' '}
      {isOpen && (
        <SelectOptionsList onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {filteredOption.map((option, index) => {
            return (
              <SelectOptionsItem
                key={index}
                onClick={event => {
                  onChange(option, event);
                  setSearchValue('');
                }}
                isSelected={option.value === value}
              >
                {option.label}
              </SelectOptionsItem>
            );
          })}
          {filteredOption.length > 0 ? '' : <SelectOptionsItem>{noResultsText}</SelectOptionsItem>}
        </SelectOptionsList>
      )}
    </>
  );
}

function MultiSelect<T>(
  props: SelectPropsMulti<T> & {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
  }
) {
  const { options, onChange, isOpen, setIsOpen, placeholder, clearable, multi } = props;
  const values = props.value;
  const selectedOptions = options.filter(option => values.includes(option.value));

  return (
    <>
      <StyledCurrentOption onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} multi={multi}>
        {selectedOptions.length > 0 ? (
          selectedOptions.map((option, index) => (
            <StyledCurrentOptions key={index}>
              {option.label}
              <StyledSelectItemClearButton
                className="material-icons"
                onClick={event => {
                  const deleteSelectedOptions = selectedOptions.filter(
                    selectedOption => selectedOption.value !== option.value
                  );
                  onChange(deleteSelectedOptions, event);
                }}
              >
                clear
              </StyledSelectItemClearButton>
            </StyledCurrentOptions>
          ))
        ) : (
          <MultiSelectOption>{placeholder}</MultiSelectOption>
        )}
        {clearable && selectedOptions.length > 0 && (
          <StyledSelectClearButton className="material-icons" onClick={() => onChange([])}>
            clear
          </StyledSelectClearButton>
        )}
        <SelectArrowIcon className="material-icons">
          {' '}
          {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </SelectArrowIcon>
      </StyledCurrentOption>{' '}
      {isOpen && (
        <SelectOptionsList onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {options.map((option, index) => {
            const isSelected = values.includes(option.value);
            return (
              <SelectOptionsItem
                key={index}
                onClick={event => {
                  const newSelectedOptions = isSelected
                    ? selectedOptions.filter(
                        selectedOption => selectedOption.value !== option.value
                      )
                    : [...selectedOptions, option];
                  onChange(newSelectedOptions, event);
                }}
                isSelected={isSelected}
              >
                {option.label}
              </SelectOptionsItem>
            );
          })}
        </SelectOptionsList>
      )}
    </>
  );
}

export default SelectGroup;

function getResponsiveClasses({ s, m, l }: SelectGroupProps<any>): string[] {
  const classes = [];

  if (s) {
    classes.push(`col-sm-${s}`);
  }
  if (m) {
    classes.push(`col-md-${m}`);
  }
  if (l) {
    classes.push(`col-lg-${l}`);
  }

  return classes;
}

function getClassStringFromArray(classes: string[]): string {
  return classes.length > 0 ? ` ${classes.join(' ')}` : '';
}

function isSoloProps<T>(props: SelectGroupProps<T>): props is SelectPropsSolo<T> {
  return !props.multi;
}

interface StyledFormGroupProps {
  disabled?: boolean;
  className?: string;
  ref: any;
  isOpen: boolean;
  onClick: () => void;
}

const StyledFormGroup: React.FC<StyledFormGroupProps> = styled.div<StyledFormGroupProps>`
  position: relative;
  user-select: none;
  margin-bottom: 30px;
  ${props => props.disabled === true && `opacity:0.5;  pointer-events: none;`}
`;

interface StyledLabelProps {
  isOpen: boolean;
}

const StyledLabel: React.FC<StyledLabelProps> = styled.label<StyledLabelProps>`
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all ease 0.3s;
  ${props => props.isOpen && `color :${PRIMARY_COLOR}; transition: all ease 0.3s;`}
`;

interface StyledCurrentOptionProps {
  multi?: boolean;
  isOpen: boolean;
  onClick: () => void;
}

const StyledCurrentOption: React.FC<StyledCurrentOptionProps> = styled.span<
  StyledCurrentOptionProps
>`
  user-select: none;
  cursor: default;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  display: block;
  outline: 0;
  position: relative;
  padding-right: 25px;
  ${props =>
    props.isOpen &&
    `border-bottom: 1px solid ${PRIMARY_COLOR}; box-shadow: 0 1px 0 0 ${PRIMARY_COLOR}; transition: all ease 0.3s;`}
  ${props => props.multi && `padding-right:40px;`}
`;

interface SelectOptionListProps {
  isOpen: boolean;
  onClick: () => void;
}

const SelectOptionsList: React.FC<SelectOptionListProps> = styled.ul<SelectOptionListProps>`
  position: absolute;
  top: 100%;
  left: 5px;
  display: block;
  width: calc(100% - 10px);
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  background: #ffffff;
  z-index: 5;
`;

interface SelectOptionsItemProps {
  onClick?: (event: any) => void;
  isSelected?: boolean;
}

const SelectOptionsItem: React.FC<SelectOptionsItemProps> = styled.li<SelectOptionsItemProps>`
  list-style: none;
  line-height: 30px;
  padding: 5px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #dedede;
  }
  ${props => props.isSelected && ` background: #dedede;`}
`;

const SelectArrowIcon = styled.i`
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.42);
`;

const StyledSelectClearButton = styled.i`
  display: block;
  position: absolute;
  right: 20px;
  bottom: 0px;
  cursor: pointer;
  height: 25px;
  text-align: center;
  z-index: 9;
  color: ${PRIMARY_COLOR_DARKER};
  &:hover {
    color: ${SECONDARY_COLOR_DARKER};
  }
`;

interface StyledInputProps {
  value: string;
  selectedOption?: any;
  placeholder?: string | JSX.Element;
  isOpen: boolean;
  clearable?: boolean;
  onClick: (event: any) => void;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
}

const StyledInput: React.FC<StyledInputProps> = styled.input<StyledInputProps>`
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  outline: none;
  background: none;
  ${props => props.selectedOption && ` ::placeholder{color:#212529;}`}
`;

const StyledCurrentOptions = styled.span`
  position: relative;
  display: inline-block;
  padding: 2px 30px 2px 10px;
  background: #dcdcdc;
  border-radius: 18px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const MultiSelectOption = styled.span`
  color: #757575;
`;

const StyledSelectItemClearButton = styled.i`
  display: block;
  position: absolute;
  right: 6px;
  bottom: 0px;
  cursor: pointer;
  height: 22px;
  text-align: center;
  z-index: 9;
  font-size: 18px;
  color: ${PRIMARY_COLOR_DARKER};
  &:hover {
    color: ${SECONDARY_COLOR_DARKER};
  }
`;
