"use strict";

var _defineProperty = require("/var/www/vhosts/preprod.oyp.fr/deploy_tools/apps/demo-calm-panda/releases/20210420162956/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$SAXOPRINT_PR, _exports$SAXOPRINT_JO;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.SAXOPRINT_PRODUCT_CODE_TRANSLATIONS = (_exports$SAXOPRINT_PR = {}, _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.FLYER, 'Flyer'), _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.DESK_CALENDAR, 'Calendrier de bureau'), _defineProperty(_exports$SAXOPRINT_PR, types_1.SAXOPRINT_PRODUCT_CODES.WALL_CALENDAR, 'Calendrier mural'), _exports$SAXOPRINT_PR);
exports.SAXOPRINT_JOB_STATES_TRANSLATIONS = (_exports$SAXOPRINT_JO = {}, _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.INVALID, 'Invalide'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.ORDERED, 'Commandé'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.UPLOADED, 'Fichier reçu'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.DATACHECK_SUCCEED, 'Vérification des info faite'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.WAITING_FOR_APPROVAL, "En attente de validation"), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.PRINTFILES_FAULTY, 'Fichiers refusés'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.PRINT_APPROVAL_SUCCEED, 'Fichiers validés'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.IN_PRINT, 'En impression'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.FINISHED, 'Fini'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.DISPATCHED, 'Expédié'), _defineProperty(_exports$SAXOPRINT_JO, types_1.SAXOPRINT_JOB_STATES.CANCELLED, 'Annulé'), _exports$SAXOPRINT_JO);