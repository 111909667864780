import { CALCULATORS, ORDER_ITEM_TYPES } from '@oyp/shared-lib';
import { FEATURE_SWITCH_SMART_LABEL_FORM } from '../../../../env-config';
import { HoverableTrigger } from '@oyp/shared-components';
import { ProductCategory } from './types';
import { SECONDARY_COLOR_DARKER } from '../../../../style_variables';
import { animated, useSpring, useTransition } from 'react-spring';
import React, { useState } from 'react';
import styled from 'styled-components';

interface CalculatorTypeFiltersProps {
  selectedCategory: ProductCategory;
  onSelection: (category: ProductCategory) => void;
  areFinishedProductsAvailable?: boolean;
}

interface Family {
  selected: boolean;
  onClick: () => void;
  label: string;
  id: number;
}

interface FamilyWithOffset extends Family {
  offset: number;
}

const CalculatorTypeFilters: React.SFC<CalculatorTypeFiltersProps> = props => {
  const { onSelection, selectedCategory, areFinishedProductsAvailable } = props;

  const categoriesLinks: { category: ProductCategory; label: string; disabled?: boolean }[] = [
    ...getCalculatorLinks(areFinishedProductsAvailable),
    //{ category: { type: ORDER_ITEM_TYPES.SMART_LABEL }, label: 'Étiquettes' },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const baseFamilies: Family[] = [
    {
      id: 1,
      selected: selectedCategory.type === ORDER_ITEM_TYPES.CALCULATOR,
      onClick: () =>
        selectedCategory.type !== ORDER_ITEM_TYPES.CALCULATOR
          ? onSelection({
              type: ORDER_ITEM_TYPES.CALCULATOR,
              selectedCalculator: CALCULATORS.FLEXIBLE,
            })
          : null,
      label: 'Grand Format',
    },
  ];

  /*if (FEATURE_SWITCH_SMART_LABEL_FORM) {
    baseFamilies.push({
      id: 2,
      selected: selectedCategory.type === ORDER_ITEM_TYPES.SMART_LABEL,
      onClick: () =>
        selectedCategory.type !== ORDER_ITEM_TYPES.SMART_LABEL
          ? onSelection({ type: ORDER_ITEM_TYPES.SMART_LABEL })
          : null,
      label: 'Petit Format',
    });
  }*/

  const selectedFamilyIndex = baseFamilies.reduce((idx: number, family, currentIndex) => {
    if (idx) {
      return idx;
    }

    return family.selected ? currentIndex : null;
  }, null as number);

  const familiesWithOffset: FamilyWithOffset[] = baseFamilies.map((family, index) => {
    return {
      ...family,
      offset: index - selectedFamilyIndex,
    };
  });

  const visibleFamilies = familiesWithOffset.filter(family => family.selected || isOpen);

  const transitions = useTransition<FamilyWithOffset, {}>(visibleFamilies, item => item.id, {
    config: { duration: 250, delay: 400 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className="bg-primary-lighter" role="group">
      <div className="row ml-0">
        <HoverableTrigger
          Wrapper={StyledFamilyContainer as any}
          onHoverInStart={() => setIsOpen(true)}
          onHoverOutEnd={() => setIsOpen(false)}
        >
          {transitions.map(({ item: family, key, props: springProps }) => (
            <FamilyLink
              key={key}
              label={family.label}
              isMenuOpen={isOpen}
              menuOffset={family.offset}
              onClick={family.onClick}
              selected={family.selected}
              springProps={springProps}
            />
          ))}
        </HoverableTrigger>
        {categoriesLinks.map((link, index) => (
          <StyledLink
            key={index}
            selected={areCategoriesEqual(link.category, selectedCategory)}
            disabled={link.disabled}
            onClick={() => !link.disabled && onSelection(link.category)}
            isHidden={areCategoriesDifferent(link.category, selectedCategory)}
          >
            {link.label}
          </StyledLink>
        ))}
      </div>
    </div>
  );
};

export default CalculatorTypeFilters;

interface LinkProps {
  selected?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
}

const StyledLink = styled.div<LinkProps>`
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 40px;
  opacity:1;
  overflow:hidden; 
  white-space: nowrap; 
  transition: width ease 0.3s;

  &:hover {
    cursor: pointer;
  }

  ${props => props.selected && `border-bottom: 3px solid ${SECONDARY_COLOR_DARKER};`}
  ${props => props.disabled && `display:none;`}
  ${props =>
    props.isHidden &&
    `padding:0; overflow:hidden; white-space: nowrap; width:0; opacity:0; transition: width ease 0.3s;`}
`;

interface FamilyLinkProps {
  selected: boolean;
  label: string;
  onClick: (event?: any) => void;
  isMenuOpen: boolean;
  menuOffset: number;
  springProps: any;
}

const FamilyLink: React.FC<FamilyLinkProps> = props => {
  const {
    springProps,
    menuOffset,
    isMenuOpen,
    selected,
    onClick = () => {},
    label,
    children,
  } = props;
  const iconName =
    isMenuOpen && FEATURE_SWITCH_SMART_LABEL_FORM ? 'keyboard_arrow_up' : 'keyboard_arrow_right';
  const springProps2 = useSpring({
    top: menuOffset * 45,
    from: { top: menuOffset * 45 },
    config: { duration: 250 },
  });

  return (
    <StyledFamilyLink {...props} onClick={onClick} style={{ ...springProps, ...springProps2 }}>
      <span>{label}</span>
      {selected && <i className="material-icons">{iconName}</i>}
      {children}
    </StyledFamilyLink>
  );
};

const StyledFamilyLink = styled(animated.div)<FamilyLinkProps>`
  display: flex;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  color: ${SECONDARY_COLOR_DARKER};
  height: 100%;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  width: 160px;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  ${props => props.selected && `background: ${SECONDARY_COLOR_DARKER}; color:#ffffff;`}
`;

const StyledFamilyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  z-index: 5;
  width: 160px;
`;

function getCalculatorLinks(areFinishedProductsAvailable?: boolean) {
  return [
    { value: CALCULATORS.FLEXIBLE, label: 'Souple' },
    { value: CALCULATORS.RIGID, label: 'Rigide' },
    {
      value: CALCULATORS.FINISHED_PRODUCT,
      label: 'Rollup & Banner',
      disabled: !areFinishedProductsAvailable,
    },
  ].map(calculatorLink => ({
    category: { type: ORDER_ITEM_TYPES.CALCULATOR, selectedCalculator: calculatorLink.value },
    label: calculatorLink.label,
    disabled: calculatorLink.disabled,
  }));
}

function areCategoriesEqual(a: ProductCategory, b: ProductCategory) {
  return (
    a.type === b.type &&
    (a.type !== ORDER_ITEM_TYPES.CALCULATOR ||
      b.type !== ORDER_ITEM_TYPES.CALCULATOR ||
      a.selectedCalculator === b.selectedCalculator)
  );
}

function areCategoriesDifferent(a: ProductCategory, b: ProductCategory) {
  return (
    a.type !== b.type &&
    (a.type !== ORDER_ITEM_TYPES.CALCULATOR ||
      b.type !== ORDER_ITEM_TYPES.CALCULATOR ||
      a.selectedCalculator === b.selectedCalculator)
  );
}
