import './ListContainer.scss';
import * as React from 'react';
import { CENTRAL_STATION_HOST } from '../../../env-config';
import { Card, WindowInfiniteLoader, formatter, restApiNetwork } from '@oyp/shared-components';
import {
  INVOICE_PRINTCENTER_STATUS_TRANSLATIONS,
  INVOICE_STATUSES,
  ModelInvoice,
} from '@oyp/shared-lib';
import { filterOrderPrefix } from '../utils';
import { invoicesEndpoint } from '../module';
import InlineIconButton from '../../../components/buttons/InlineIconButton';
import LoaderRow from '../../../components/list/LoaderRow';
import SideNavigationMenu, { NAVIGATION_LINK_IDS } from '../../../components/SideNavigationMenu';

const { fetchCollection } = restApiNetwork;
const { formatDateStringToShortText } = formatter;

interface InvoiceListContainerState {
  invoices: ModelInvoice[];
  isLoading: boolean;
  listMeta: any;
  filters: any;
}

class InvoiceListContainer extends React.Component {
  state: InvoiceListContainerState = {
    invoices: [],
    isLoading: true,
    listMeta: {},
    filters: {
      count: 10,
      page: 0,
    },
  };

  constructor(props: {}) {
    super(props);

    this.loadInvoices = this.loadInvoices.bind(this);
    this.handleLoadMoreItems = this.handleLoadMoreItems.bind(this);
  }

  componentDidMount(): void {
    this.loadInvoices();
  }

  handleLoadMoreItems() {
    const { listMeta, invoices, filters } = this.state;

    return listMeta.totalCount > invoices.length
      ? this.loadInvoices({ page: filters.page + 1 }, true)
      : null;
  }

  async sortList(name: string) {
    const prefix = filterOrderPrefix(this.state.filters, name);

    await this.loadInvoices({ sort: prefix + name, page: 0 });
  }

  async loadInvoices(filterSelection = {}, addToList = false) {
    const filters = {
      ...this.state.filters,
      ...filterSelection,
    };

    this.setState({
      isLoading: true,
      filters,
    });

    const invoices = await fetchCollection(invoicesEndpoint, {
      ...filters,
    });

    this.setState({
      ...this.state,
      listMeta: invoices.meta,
      invoices: addToList ? [...this.state.invoices, ...invoices.data] : invoices.data,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, invoices } = this.state;
    const today = new Date();

    return (
      <div>
        <div className="reseller-app-page-title">MON COMPTE</div>
        <div className="user-container">
          <div className="row ml-0 mr-0">
            <div className="col-md-3 laptop-padding-right">
              <SideNavigationMenu activeLink={NAVIGATION_LINK_IDS.INVOICES_LIST} />
            </div>
            <div className="col-md-9 laptop-padding-left">
              <Card className="main-card invoices-list" title={'MES FACTURES'}>
                <div className="result-container">
                  <table className="table table-borderless table-striped">
                    <thead>
                      <tr>
                        <th className="bg-primary-lighter text-left font-weight-bold text-white" />
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Numéro
                        </th>

                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Date de création{' '}
                          <i
                            onClick={() => this.sortList('billingDate')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>

                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Total TTC{' '}
                          <i
                            onClick={() => this.sortList('taxIncludedTotal')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Date limite de paiement{' '}
                          <i
                            onClick={() => this.sortList('dueDate')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Statut
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {invoices.map((invoice, key) => {
                        const dueDate = new Date(invoice.dueDate);

                        return (
                          <tr key={key}>
                            <td className="action-cell">
                              <InlineIconButton
                                as="a"
                                href={`${CENTRAL_STATION_HOST}/download-invoice/${invoice.id}`}
                                download
                                iconName="get_app"
                              />
                            </td>
                            <td>{invoice.reference}</td>

                            <td>{formatDateStringToShortText(invoice.billingDate)}</td>
                            <td>{invoice.taxIncludedTotal.toFixed(2)} €</td>
                            <td>{formatDateStringToShortText(dueDate)}</td>
                            <td
                              className={`font-weight-bold ${
                                invoice.status !== INVOICE_STATUSES.PAID && dueDate < today
                                  ? `text-danger`
                                  : null
                              }`}
                            >
                              {INVOICE_PRINTCENTER_STATUS_TRANSLATIONS[invoice.status]}{' '}
                            </td>
                          </tr>
                        );
                      })}
                      {isLoading ? <LoaderRow /> : null}
                    </tbody>
                  </table>
                  <WindowInfiniteLoader
                    isLoading={isLoading}
                    onLoadMore={this.handleLoadMoreItems}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceListContainer;
