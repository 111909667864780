import * as React from 'react';
import InfoTooltip from '../../../components/InfoTooltip';

interface AmalgamumTooltipProps {
  left?: number;
  top?: number;
}

const AmalgamumTooltip: React.FC<AmalgamumTooltipProps> = props => {
  const { left = 140, top = -2 } = props;

  return (
    <InfoTooltip
      left={left}
      top={top}
      place="top"
      tooltipContent={
        <div>
          Vous pouvez envoyer plusieurs fichiers pour 1 même produit.
          <br />
          Merci de nommer les fichiers avec la nomenclature suivante :<br />
          <br />
          <b>numero_du_visuel-nom_du_fichier-quantité.extension</b>
          <br />
          <br />
          <em>
            ex : pour 10ex avec 3 fichiers :<br />
            1-visuelA-3ex.pdf
            <br />
            2-visuelB-3ex.jpg
            <br />
            3-visuelC-4ex.pdf
          </em>
        </div>
      }
    />
  );
};

export default AmalgamumTooltip;
