import './App.scss';
import * as H from 'history';
import * as React from 'react';
import { MDBToastContainer } from 'mdbreact';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { UserContext, UserContextInterface, defaultUserContext } from './UserContext';
import {
  UserMenuContext,
  UserMenuContextInterface,
  defaultUserMenuContext,
} from './UserMenuContext';
import { centralStationApiEndpoint } from './env-config';
import { checkToken, resetToken } from './_modules/authentication/actions';
import { fetchOneReseller } from './_modules/user/actions';
import { fetchWithToken } from '@oyp/shared-components/dist/lib/rest-api/network';
import { resellerMenuDataEndpoint } from './_modules/user/module';
import { restApiNetwork } from '@oyp/shared-components';
import Footer from './components/menu/Footer';
import Header from './containers/Header';
import Loader from './components/Loader';
import Login, { UserPostLoginInfo } from './_modules/authentication/containers/Login';
import Main from './components/Main';
import RequestPasswordResetForm from './_modules/authentication/components/RequestPasswordResetForm';
import ResetPasswordForm from './_modules/authentication/components/ResetPasswordForm';
import websocket from './websocket';

const { fetchOne } = restApiNetwork;

interface AppState {
  userContext: UserContextInterface;
  userMenuContext: UserMenuContextInterface;
  isInitialLoading?: boolean;
  displayInitialTransition?: boolean;
}

class App extends React.Component<RouteComponentProps, AppState> {
  state: AppState = {
    userContext: defaultUserContext,
    userMenuContext: defaultUserMenuContext,
    isInitialLoading: true,
    displayInitialTransition: false,
  };
  refreshUserMenuIntervalId: any = null;

  constructor(props: RouteComponentProps) {
    super(props);

    this.handleUserLogin = this.handleUserLogin.bind(this);
    this.handleUserLogout = this.handleUserLogout.bind(this);

    //Si on est en mode mascarade, on efface le token existant
    if(window.location.search.includes('mascaradeKey')){
      resetToken();
    }
    // Au chargement, on vérifie si le token de l'utilisateur stocké dans les cookies est bon
    this.checkUserToken();
  }

  public render() {
    const { userContext, isInitialLoading, displayInitialTransition, userMenuContext } = this.state;

    if (displayInitialTransition) {
      return (
        <div className="App background">
          <Loader isWholePage />
        </div>
      );
    }
    if (isInitialLoading) {
      return (
        <div className="App background loading-initial-data">
          <Loader isWholePage />
        </div>
      );
    }

    return (
      <div className="App background reseller-app">
        {this.state.userContext.userId ? (
          <UserContext.Provider value={userContext}>
            <UserMenuContext.Provider value={userMenuContext}>
              <Header onLogout={this.handleUserLogout} />
              <Main />
              <Footer isAuthenticated />
            </UserMenuContext.Provider>
          </UserContext.Provider>
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={props => <Login {...props} onSuccessfulLogin={this.handleUserLogin} />}
            />
            <Route exact path="/reset-password" component={RequestPasswordResetForm} />
            <Route exact path="/reset-password/:token" component={ResetPasswordForm} />
          </Switch>
        )}
        <MDBToastContainer hideProgressBar={true} newestOnTop={true} autoClose={5000} />
      </div>
    );
  }

  async handleUserLogin(user: UserPostLoginInfo) {
    if (user) {
      websocket.createSocket();

      const [reseller, startupAppVersion] = await Promise.all([
        fetchOneReseller(user.resellerId),
        fetchAppVersion(),
      ]);

      this.setState({
        isInitialLoading: false,
        displayInitialTransition: false,
        userContext: {
          userId: user.userId,
          reseller,
          token: user.token,
          resellerUserId: user.resellerUserId,
          startupAppVersion,
        },
      });

      await this.loadUserMenu();
      this.startPollingUserMenuRefresh();
    }
  }

  handleUserLogout() {
    resetToken();
    localStorage.setItem('mascaradeMode', 'false');
    this.props.history.push('/');

    this.stopPollingUserMenuRefresh();
    this.setState({ userContext: defaultUserContext });
  }

  async checkUserToken() {
    try {
      const tokenResponse = await checkToken();

      if (tokenResponse) {
        this.setState({ displayInitialTransition: true });
        setTimeout(() => this.handleUserLogin(tokenResponse), 700);
      } else {
        forceLocationToHome(this.props.history);

        this.setState({ isInitialLoading: false });
      }
    } catch (error) {
      forceLocationToHome(this.props.history);

      this.setState({ isInitialLoading: false });
    }
  }

  async loadUserMenu() {
    const { reseller } = this.state.userContext;

    const newUserMenuContext = await getResellerUserMenuData(reseller.id);

    if (areUserMenuContextsDifferent(this.state.userMenuContext, newUserMenuContext)) {
      this.setState({
        userMenuContext: newUserMenuContext,
      });
    }
  }

  startPollingUserMenuRefresh() {
    this.refreshUserMenuIntervalId = setInterval(() => {
      this.loadUserMenu();
    }, 5000);
  }

  stopPollingUserMenuRefresh() {
    if (this.refreshUserMenuIntervalId) {
      clearInterval(this.refreshUserMenuIntervalId);
      this.refreshUserMenuIntervalId = null;
    }
  }
}

export default withRouter(App);

async function getResellerUserMenuData(resellerId: string) {
  return fetchOne<UserMenuContextInterface>(resellerMenuDataEndpoint, resellerId);
}

function areUserMenuContextsDifferent(
  context1: UserMenuContextInterface,
  context2: UserMenuContextInterface
) {
  return (
    context1.lateInvoicesCount !== context2.lateInvoicesCount ||
    context1.invalidOrdersCount !== context2.invalidOrdersCount ||
    context1.quotesToValidateCount !== context2.quotesToValidateCount
  );
}

function forceLocationToHome(history: H.History) {
  const pathname = window.location.pathname;

  if (pathname !== '/' && !pathname.includes('reset-password')) {
    history.push('/');
  }
}

async function fetchAppVersion(): Promise<string | undefined> {
  return (await (await fetchWithToken(`${centralStationApiEndpoint}/app-version`)).json()).version;
}
