import './ListContainer.scss';
import * as React from 'react';
import { Card, WindowInfiniteLoader } from '@oyp/shared-components';
import {
  ModelOrder,
  ModelOrderItem,
  ModelShippingAddress,
  ModelUser,
  ORDER_SCOPE_QUOTE_ONLY,
  mapId,
} from '@oyp/shared-lib';
import { UserContext, UserContextInterface } from '../../../UserContext';
import { deleteOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { fetchOrderItemsForOrder } from '../actions/order_item';
import { fetchOrdersWithProprietaryEntities } from '../actions/order';
import { fetchResellerUsersForReseller } from '../actions/reseller';
import { filterOrderPrefix } from '../utils';
import { getUserDisplayName } from '@oyp/shared-components/dist/lib/utils/names';
import { orderApiEndpoint } from '../module';
import { toast } from 'mdbreact';
import DebouncedInputGroup from '../../../components/DebouncedInputGroup';
import LoaderRow from '../../../components/list/LoaderRow';
import QuoteListRow from '../components/QuoteListRow';
import SelectGroup, { SelectOption } from '../../../components/form/SelectGroup';
import SideNavigationMenu, { NAVIGATION_LINK_IDS } from '../../../components/SideNavigationMenu';

interface QuoteListFilter {
  quoteReference: string;
  page: number;
  count: number;
  sort?: string;
  creatorId?: string;
  search?: string;
  shippingAddressSearch?: string;
}

interface QuoteListContainerState {
  orders: ModelOrderWithSubEntities[];
  isLoading: boolean;
  filters: QuoteListFilter;
  resellerUsers: ModelUser[];
  usersOptions: any[];
  statusOptions: any[];
  userIds: any[];
  listMeta?: { totalCount: number };
}

interface ModelOrderWithSubEntities extends ModelOrder {
  orderItems: ModelOrderItem[];
  shippingAddress?: ModelShippingAddress;
  creator?: ModelUser;
}

class QuoteListContainer extends React.Component<{}, QuoteListContainerState> {
  state: QuoteListContainerState = {
    orders: [],
    isLoading: true,
    userIds: [],
    filters: {
      quoteReference: '',
      count: 20,
      page: 0,
      sort: '-createdAt',
    },
    resellerUsers: [],
    usersOptions: [],
    statusOptions: [],
  };

  context: UserContextInterface;
  constructor(props: {}, context: UserContextInterface) {
    super(props);

    this.handleCreatorFilterChange = this.handleCreatorFilterChange.bind(this);
    this.loadQuotes = this.loadQuotes.bind(this);
    this.handleLoadMoreItems = this.handleLoadMoreItems.bind(this);
    this.handleDeleteQuote = this.handleDeleteQuote.bind(this);

    this.loadInitialData(context);
  }

  render() {
    const { isLoading, orders: quotes, filters, usersOptions } = this.state;

    return (
      <div>
        <div className="reseller-app-page-title">MON COMPTE</div>
        <div className="user-container quotes-list-container">
          <div className="row ml-0 mr-0">
            <div className="col-md-3 laptop-padding-right">
              <SideNavigationMenu activeLink={NAVIGATION_LINK_IDS.QUOTES_LIST} />
            </div>
            <div className="col-md-9 laptop-padding-left">
              <Card className="main-card quotes-list" title={'MES DEVIS'}>
                <div className="list-filters-container">
                  <div className="row ml-0 mr-0">
                    <div className="col-sm-12">
                      <div className="form-row m-0">
                        <DebouncedInputGroup
                          name="search"
                          label="Numéro ou référence"
                          hint="Numéro ou référence de mon devis"
                          s={4}
                          value={filters.search}
                          handleChange={search => this.loadQuotes({ search })}
                        />
                        <SelectGroup
                          s={4}
                          placeholder="Rechercher"
                          noResultsText="Aucun résultat"
                          onChange={(selectedOption: SelectOption<string>) =>
                            this.handleCreatorFilterChange(selectedOption)
                          }
                          options={usersOptions}
                          clearable={true}
                          searchable={true}
                          value={filters.creatorId}
                          label="Créateur"
                        />
                        <DebouncedInputGroup
                          name="shippingAddressSearch"
                          label="Destinataire / Adresse de livraison"
                          hint="Rechercher dans une adresse"
                          s={4}
                          value={filters.shippingAddressSearch}
                          handleChange={shippingAddressSearch =>
                            this.loadQuotes({ shippingAddressSearch })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="result-container">
                  <table className="table table-borderless table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        />
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Numéro
                        </th>
                        <th className="bg-primary-lighter text-left font-weight-bold text-white">
                          Référence
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Destinataire
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Date de création{' '}
                          <i
                            onClick={() => this.sortList('createdAt')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                        >
                          Créateur
                        </th>
                        <th
                          scope="col"
                          className="bg-primary-lighter text-left font-weight-bold text-white"
                          align="right"
                        >
                          Total TTC{' '}
                          <i
                            onClick={() => this.sortList('taxIncludedTotal')}
                            className="fa fa-sort pointer float-right"
                          />
                        </th>

                        <th className="bg-primary-lighter text-left font-weight-bold text-white" />
                      </tr>
                    </thead>

                    <tbody>
                      {quotes.map((quote, index) => (
                        <QuoteListRow key={index} quote={quote} onDelete={this.handleDeleteQuote} />
                      ))}
                      {isLoading ? <LoaderRow /> : null}
                    </tbody>
                  </table>
                  <WindowInfiniteLoader
                    isLoading={isLoading}
                    onLoadMore={this.handleLoadMoreItems}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleLoadMoreItems() {
    const { listMeta, orders, filters } = this.state;

    return listMeta.totalCount > orders.length
      ? this.loadQuotes({ page: filters.page + 1 }, true)
      : null;
  }

  async loadInitialData(context: UserContextInterface) {
    const { reseller } = context;

    const users = await fetchResellerUsersForReseller(reseller.id);
    const userIds = users.map(mapId);

    await this.loadQuotes();

    this.setState({
      isLoading: false,
      userIds,
      resellerUsers: users,

      usersOptions: users.map(user => ({
        value: user.id,
        label: getUserDisplayName(user),
      })),
    });
  }

  async sortList(name: string) {
    const prefix = filterOrderPrefix(this.state.filters, name);

    await this.loadQuotes({ sort: prefix + name });
  }

  async loadQuotes(filterSelection: Partial<QuoteListFilter> = {}, addToList = false) {
    const filters = {
      ...this.state.filters,
      ...filterSelection,
      page: addToList ? filterSelection.page : 0,
    };

    this.setState({
      isLoading: true,
      filters,
    });

    const orderCollection = await fetchOrdersWithProprietaryEntities({
      ...filters,
      scope: ORDER_SCOPE_QUOTE_ONLY,
    });

    const ordersWithDetails: ModelOrderWithSubEntities[] = await Promise.all(
      orderCollection.orders.map(async order => {
        const orderItems = await fetchOrderItemsForOrder(order.id);

        return {
          ...order,
          orderItems: orderItems.map(orderItem => ({ ...orderItem, order })),
        };
      })
    );

    this.setState({
      orders: addToList ? [...this.state.orders, ...ordersWithDetails] : ordersWithDetails,
      listMeta: orderCollection.meta,
      isLoading: false,
    });

    return ordersWithDetails;
  }

  async handleCreatorFilterChange(option: SelectOption<string>) {
    if (option === undefined) {
      await this.loadQuotes({ creatorId: undefined });
    } else {
      await this.loadQuotes({ creatorId: option.value });
    }
  }

  async handleDeleteQuote(quote: ModelOrder) {
    await deleteOne(orderApiEndpoint, quote);
    toast.success('Devis supprimé', 1000);
    await this.loadQuotes();
  }
}

QuoteListContainer.contextType = UserContext;

export default QuoteListContainer;
