"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Enums

var CALCULATORS;

(function (CALCULATORS) {
  CALCULATORS["FLEXIBLE"] = "CALCULATOR_FLEXIBLE";
  CALCULATORS["RIGID"] = "CALCULATOR_RIGID";
  CALCULATORS["KAKEMONO"] = "CALCULATOR_KAKEMONO";
  CALCULATORS["TOTEBAG"] = "CALCULATOR_TOTEBAG";
  CALCULATORS["FINISHED_PRODUCT"] = "CALCULATOR_FINISHED_PRODUCT";
})(CALCULATORS = exports.CALCULATORS || (exports.CALCULATORS = {}));

var BUNDLE_TYPES;

(function (BUNDLE_TYPES) {
  BUNDLE_TYPES["FULL"] = "bundleType.full";
  BUNDLE_TYPES["MATERIAL_ONLY"] = "bundleType.material_only";
  BUNDLE_TYPES["STRUCTURE_ONLY"] = "bundleType.structure_only";
})(BUNDLE_TYPES = exports.BUNDLE_TYPES || (exports.BUNDLE_TYPES = {}));

var CUT_TYPES;

(function (CUT_TYPES) {
  CUT_TYPES["STRAIGHT"] = "cut_type.straight";
  CUT_TYPES["SHAPE"] = "cut_type.shape";
})(CUT_TYPES = exports.CUT_TYPES || (exports.CUT_TYPES = {}));

var PRODUCTION_MODES;

(function (PRODUCTION_MODES) {
  PRODUCTION_MODES["STANDARD"] = "PRODUCTION_MODE_STANDARD";
  PRODUCTION_MODES["EXPRESS"] = "PRODUCTION_MODE_EXPRESS"; // Prioritaire

  PRODUCTION_MODES["OVERNIGHT"] = "PRODUCTION_MODE_OVERNIGHT";
})(PRODUCTION_MODES = exports.PRODUCTION_MODES || (exports.PRODUCTION_MODES = {}));

var SHIPPING_MODES;

(function (SHIPPING_MODES) {
  SHIPPING_MODES["TRANSPORTER"] = "SHIPPING_MODE_TRANSPORTER"; // Coursier

  SHIPPING_MODES["COURIER"] = "SHIPPING_MODE_COURIER"; // Pas de livraison

  SHIPPING_MODES["PICKUP"] = "SHIPPING_MODE_PICKUP";
})(SHIPPING_MODES = exports.SHIPPING_MODES || (exports.SHIPPING_MODES = {}));

var EYELET_TYPES;

(function (EYELET_TYPES) {
  EYELET_TYPES["NONE"] = "EYELET_NONE";
  EYELET_TYPES["FOUR_ANGLES"] = "eyelet_type.four_angles";
  EYELET_TYPES["PERIMETER"] = "eyelet_type.perimeter";
  EYELET_TYPES["UP"] = "eyelet_type.up";
  EYELET_TYPES["DOWN"] = "eyelet_type.down";
  EYELET_TYPES["UP_DOWN"] = "eyelet_type.up_down";
  EYELET_TYPES["LEFT"] = "eyelet_type.left";
  EYELET_TYPES["RIGHT"] = "eyelet_type.right";
  EYELET_TYPES["LEFT_RIGHT"] = "eyelet_type.left_right";
  EYELET_TYPES["UP_TWO_ANGLES"] = "eyelet_type.up_two_angles";
})(EYELET_TYPES = exports.EYELET_TYPES || (exports.EYELET_TYPES = {}));

exports.FLEXIBLE_EYELET_TYPES = [EYELET_TYPES.NONE, EYELET_TYPES.FOUR_ANGLES, EYELET_TYPES.PERIMETER, EYELET_TYPES.UP, EYELET_TYPES.DOWN, EYELET_TYPES.UP_DOWN, EYELET_TYPES.LEFT, EYELET_TYPES.RIGHT, EYELET_TYPES.LEFT_RIGHT, EYELET_TYPES.UP_TWO_ANGLES];
exports.RIGID_EYELET_TYPES = [EYELET_TYPES.NONE, EYELET_TYPES.UP, EYELET_TYPES.FOUR_ANGLES, EYELET_TYPES.UP_DOWN, EYELET_TYPES.LEFT_RIGHT];
exports.FLEXIBLE_EYELET_TYPES_WITH_INTERVAL = [EYELET_TYPES.PERIMETER, EYELET_TYPES.UP_DOWN, EYELET_TYPES.UP, EYELET_TYPES.DOWN, EYELET_TYPES.LEFT_RIGHT, EYELET_TYPES.LEFT, EYELET_TYPES.RIGHT];
var EYELET_INTERVALS;

(function (EYELET_INTERVALS) {
  EYELET_INTERVALS["TEN"] = "10";
  EYELET_INTERVALS["TWENTY_FIVE"] = "25";
  EYELET_INTERVALS["FIFTY"] = "50";
  EYELET_INTERVALS["HUNDRED"] = "100";
})(EYELET_INTERVALS = exports.EYELET_INTERVALS || (exports.EYELET_INTERVALS = {}));

var PERFORATION_TYPES;

(function (PERFORATION_TYPES) {
  PERFORATION_TYPES["NONE"] = "PERFORATION_NONE";
  PERFORATION_TYPES["FOUR_ANGLES"] = "perforation_type.four_angles";
  PERFORATION_TYPES["UP"] = "perforation_type.up";
  PERFORATION_TYPES["UP_DOWN"] = "perforation_type.up_down";
  PERFORATION_TYPES["LEFT_RIGHT"] = "perforation_type.left_right";
})(PERFORATION_TYPES = exports.PERFORATION_TYPES || (exports.PERFORATION_TYPES = {}));

var PRINTING_TYPES;

(function (PRINTING_TYPES) {
  PRINTING_TYPES["ONE_SIDED"] = "printing_type.one_sided";
  PRINTING_TYPES["TWO_SIDED"] = "printing_type.two_sided";
  PRINTING_TYPES["NONE"] = "printing_type.none";
  PRINTING_TYPES["MIRROR"] = "printing_type.mirror";
})(PRINTING_TYPES = exports.PRINTING_TYPES || (exports.PRINTING_TYPES = {}));

var WHITE_LAYER_TYPES;

(function (WHITE_LAYER_TYPES) {
  // Sans
  WHITE_LAYER_TYPES["NONE"] = "WHITE_LAYER_TYPE_NONE"; // Blanc seul

  WHITE_LAYER_TYPES["SELECTIVE_WHITE"] = "WHITE_LAYER_TYPE_SELECTIVE_WHITE"; // Sélectif + quadri

  WHITE_LAYER_TYPES["SELECTIVE"] = "WHITE_LAYER_TYPE_SELECTIVE"; // Total + quadri

  WHITE_LAYER_TYPES["TOTAL"] = "WHITE_LAYER_TYPE_TOTAL"; // Ces deux options sont dépréciées, seulement gardées pour les commandes déjà passées

  WHITE_LAYER_TYPES["SELECTIVE_MIRROR"] = "WHITE_LAYER_TYPE_SELECTIVE_MIRROR";
  WHITE_LAYER_TYPES["TOTAL_MIRROR"] = "WHITE_LAYER_TYPE_TOTAL_MIRROR";
})(WHITE_LAYER_TYPES = exports.WHITE_LAYER_TYPES || (exports.WHITE_LAYER_TYPES = {}));

var TRANSPORTERS;

(function (TRANSPORTERS) {
  TRANSPORTERS["NO_TRANSPORTER"] = "transporter.no_transporter";
  TRANSPORTERS["DPD"] = "transporter.dpd";
  TRANSPORTERS["CHRONOPOST"] = "transporter.chronopost";
  TRANSPORTERS["PALLET"] = "transporter.pallet";
  TRANSPORTERS["SAXOPRINT"] = "transporter.saxoprint";
  TRANSPORTERS["COURIER"] = "transporter.courier";
})(TRANSPORTERS = exports.TRANSPORTERS || (exports.TRANSPORTERS = {}));

var CALCULATOR_VALIDATION_ERROR_MESSAGES;

(function (CALCULATOR_VALIDATION_ERROR_MESSAGES) {
  CALCULATOR_VALIDATION_ERROR_MESSAGES["MULTI_ROLL"] = "validation.multi_roll";
  CALCULATOR_VALIDATION_ERROR_MESSAGES["MULTI_ROLL_LAMINATION"] = "validation.multi_roll_lamination";
  CALCULATOR_VALIDATION_ERROR_MESSAGES["MULTI_SLAB"] = "validation.multi_slab";
  CALCULATOR_VALIDATION_ERROR_MESSAGES["MULTI_SLAB_UNAVAILABLE"] = "validation.multi_slab.unavailable";
  CALCULATOR_VALIDATION_ERROR_MESSAGES["MULTI_SLAB_PERFORATION"] = "VALIDATION_MESSAGE_MULTI_SLAB_PERFORATION";
})(CALCULATOR_VALIDATION_ERROR_MESSAGES = exports.CALCULATOR_VALIDATION_ERROR_MESSAGES || (exports.CALCULATOR_VALIDATION_ERROR_MESSAGES = {})); // Finished product


var FINISHED_PRODUCT_CATEGORIES;

(function (FINISHED_PRODUCT_CATEGORIES) {
  FINISHED_PRODUCT_CATEGORIES["ROLLUP"] = "FINISHED_PRODUCT_CATEGORY_ROLLUP";
  FINISHED_PRODUCT_CATEGORIES["XBANNER"] = "FINISHED_PRODUCT_CATEGORY_XBANNER"; // Extérieur

  FINISHED_PRODUCT_CATEGORIES["OUTDOOR"] = "FINISHED_PRODUCT_CATEGORY_OUTDOOR";
})(FINISHED_PRODUCT_CATEGORIES = exports.FINISHED_PRODUCT_CATEGORIES || (exports.FINISHED_PRODUCT_CATEGORIES = {}));