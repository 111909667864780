import * as Either from 'fp-ts/lib/Either';
import { CENTRAL_STATION_HOST } from '../../../../../env-config';
import {
  CUT_TYPES,
  CUT_TYPE_TRANSLATIONS,
  CalculatorLamination,
  CalculatorMaterial,
  CalculatorRequestData,
  CalculatorRigidFormPermanentResources,
  EYELET_TYPES,
  InprintCalculator,
  ORDER_ITEM_TYPES,
  PERFORATION_TYPES,
  PERFORATION_TYPE_TRANSLATIONS,
  PRINTING_TYPES,
  RIGID_EYELET_TYPES,
  RIGID_EYELET_TYPE_TRANSLATIONS,
  TYPE_PLEXI,
  WHITE_LAYER_TYPES,
} from '@oyp/shared-lib';
import { Card, form, getRigidFormConfig } from '@oyp/shared-components';
import { FrontCalculator } from '../../../components/Calculator';
import { WHITE_LAYER_RADIO_OPTIONS } from '../../../calculator_helpers';
import { getRemoteMessages } from '../../../validation';
import AddToCartButton from '../../../components/AddToCartButton';
import AmalgamumTooltip from '../../../components/AmalgamumTooltip';
import CardActionFooter from '../../../../../components/CardActionFooter';
import CostCardForm from '../../../components/CostCardForm';
import FilterButton from '../../../components/FilterButton';
import GlobalCalculateMessages from '../../../components/GlobalCalculateMessages';
import InfoTooltip from '../../../../../components/InfoTooltip';
import InputGroup from '../../../../../components/form/InputGroup';
import Markdown from 'react-markdown';
import OrderItemFields from '../../../components/OrderItemFields';
import React from 'react';
import ResetButton from '../../../components/ResetButton';
import SelectGroup, { SelectOption } from '../../../../../components/form/SelectGroup';
import TechnicalInformation from '../../../components/TechnicalInformation';

const { getOnValueChange, getOnEventChange } = form;

const CalculatorForm: React.SFC<FrontCalculator.FormProps<
  CalculatorRigidFormPermanentResources,
  CalculatorRequestData,
  InprintCalculator.SlimResult
>> = props => {
  const {
    permanentResources,
    initialRequestData,
    requestData,
    result,
    isLoading,
    onCalculate,
    onAddToCart,
    onReset,
    cartProps,
    isEditingItem,
  } = props;

  const { materials, laminations, materialTypes = [] } = permanentResources;

  const selectedMaterial: CalculatorMaterial = materials.find(m => m.id === requestData.materialId);
  const selectedMaterialType = selectedMaterial.type;

  const {
    disableLamination,
    disableEyelet,
    disableUseWhiteLayer,
    disablePerforation,
    disableCutTypeShape,
    disableCreasing,
  } = getRigidFormConfig(permanentResources, requestData);

  const {
    dimensions,
    cutType,
    whiteLayerType,
    quantity,
    imageryCount,
    printingType,
    creasing,
    perforation,
    eyelet,
    // passedForPrint = false,
  } = requestData;

  const materialOptions = makeMaterialOptions(materials, selectedMaterialType);
  const laminationOptions = makeLaminationOptions(laminations, selectedMaterial);

  const errorsByProperty = getRemoteMessages(Either.isLeft(result) ? result.left.errors : []);
  const warningsByProperty = getRemoteMessages(Either.isRight(result) ? result.right.warnings : []);

  const onMaterialChange = getOnValueChange('materialId', requestData, onCalculate);
  const onLaminationChange = getOnValueChange('laminationId', requestData, onCalculate);
  const onEyeletChange = getOnValueChange('eyelet', requestData, onCalculate);
  const onPerforationChange = getOnValueChange('perforation', requestData, onCalculate);

  //Définit la valeur par défaut du blanc à Total + quadri si le type d'impression est recto ou miroir
  const onPrintingTypeChange = (value: PRINTING_TYPES) => {
    const isWhiteLayer = materials.find(m => m.id === requestData.materialId).white;
    const updatedRequestData =
      isWhiteLayer && disableUseWhiteLayer
        ? { ...requestData, whiteLayerType: WHITE_LAYER_TYPES.TOTAL }
        : requestData;
    onCalculate({ ...updatedRequestData, printingType: value });
  };

  const onWhiteLayerTypeChange = getOnValueChange('whiteLayerType', requestData, onCalculate);
  const onCutTypeChange = getOnValueChange('cutType', requestData, onCalculate);
  const onCreasingChange = getOnValueChange('creasing', requestData, onCalculate);
  // const onPassedForPrintChange = getOnValueChange('passedForPrint', requestData, onCalculate);

  //Gère l'affichage pour le cas du miroir
  const printingTypeOptions =
    selectedMaterialType === TYPE_PLEXI
      ? [
          { value: PRINTING_TYPES.MIRROR, label: 'Miroir' },
          { value: PRINTING_TYPES.ONE_SIDED, label: 'Recto' },
          { value: PRINTING_TYPES.NONE, label: 'Sans' },
        ]
      : [
          { value: PRINTING_TYPES.ONE_SIDED, label: 'Recto' },
          { value: PRINTING_TYPES.TWO_SIDED, label: 'Recto/Verso' },
          { value: PRINTING_TYPES.NONE, label: 'Sans' },
        ];

  const canAddToCart =
    Object.keys(errorsByProperty).length === 1 && errorsByProperty.global.length === 0;
  return (
    <div className="form-page-container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 configurator">
              <div className="row">
                <div className="col-md-12">
                  <div className="filter-container" role="group">
                    {materialTypes.map((materialType, index) => (
                      <FilterButton
                        key={index}
                        handleClick={type =>
                          onCalculate({
                            ...initialRequestData,
                            materialId: materials.find(m => m.type === type).id,
                            printingType:
                              materialType.type === TYPE_PLEXI
                                ? PRINTING_TYPES.MIRROR
                                : PRINTING_TYPES.ONE_SIDED,
                            whiteLayerType:
                              materialType.type === TYPE_PLEXI
                                ? WHITE_LAYER_TYPES.TOTAL
                                : WHITE_LAYER_TYPES.NONE,
                          })
                        }
                        filterType={{
                          ...materialType,
                          isActive: materialType.type === selectedMaterialType,
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 calculator-container">
                  <div className="form-row">
                    <SelectGroup
                      s={12}
                      label={
                        <span>
                          Matière
                          {selectedMaterial.description && (
                            <InfoTooltip
                              left={55}
                              tooltipContent={<Markdown source={selectedMaterial.description} />}
                              contentClassName="calculator-tooltip-description"
                            />
                          )}
                        </span>
                      }
                      options={materialOptions}
                      name="material"
                      value={requestData.materialId}
                      onChange={(option: SelectOption<string>) => onMaterialChange(option.value)}
                      clearable={false}
                      searchable={false}
                    />
                  </div>

                  <div className="form-row">
                    <InputGroup
                      name="dimensions.width"
                      label="Largeur (cm)"
                      s={3}
                      type="number"
                      error={errorsByProperty.width || errorsByProperty.surface}
                      warning={warningsByProperty.width || warningsByProperty.surface}
                      onChange={getOnEventChange('dimensions.width', requestData, onCalculate)}
                      value={dimensions.width}
                    />

                    <InputGroup
                      name="dimensions.height"
                      label="Hauteur (cm)"
                      s={3}
                      type="number"
                      error={errorsByProperty.height}
                      warning={warningsByProperty.height}
                      onChange={getOnEventChange('dimensions.height', requestData, onCalculate)}
                      value={dimensions.height}
                    />

                    <InputGroup
                      name="quantity"
                      label="Quantité"
                      s={3}
                      type="number"
                      error={errorsByProperty.quantity}
                      warning={warningsByProperty.quantity}
                      onChange={getOnEventChange('quantity', requestData, onCalculate)}
                      value={quantity}
                    />

                    <InputGroup
                      name="imageryCount"
                      label={
                        <span>
                          Nb fichiers (amalgame)
                          <AmalgamumTooltip />
                        </span>
                      }
                      className="amalgame"
                      s={3}
                      type="number"
                      error={errorsByProperty.imageryCount}
                      warning={warningsByProperty.imageryCount}
                      onChange={getOnEventChange('imageryCount', requestData, onCalculate)}
                      value={printingType === PRINTING_TYPES.NONE ? 0 : imageryCount}
                      disabled={printingType === PRINTING_TYPES.NONE}
                    />
                  </div>
                  <div className="form-row">
                    <SelectGroup
                      s={3}
                      label="Type d'impression"
                      options={printingTypeOptions}
                      name="printingType"
                      value={printingType}
                      onChange={(option: SelectOption<PRINTING_TYPES>) =>
                        onPrintingTypeChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                    />
                    <SelectGroup
                      s={3}
                      label="Blanc de soutien"
                      options={WHITE_LAYER_RADIO_OPTIONS}
                      name="whiteLayerType"
                      value={disableUseWhiteLayer ? null : whiteLayerType}
                      onChange={(option: SelectOption<WHITE_LAYER_TYPES>) =>
                        onWhiteLayerTypeChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableUseWhiteLayer}
                      placeholder={disableUseWhiteLayer ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={3}
                      label="Découpe"
                      options={[
                        {
                          value: CUT_TYPES.STRAIGHT,
                          label: CUT_TYPE_TRANSLATIONS[CUT_TYPES.STRAIGHT],
                        },
                        {
                          value: CUT_TYPES.SHAPE,
                          label: CUT_TYPE_TRANSLATIONS[CUT_TYPES.SHAPE],
                        },
                      ]}
                      name="cutType"
                      value={disableCutTypeShape ? null : cutType}
                      onChange={(option: SelectOption<CUT_TYPES>) => onCutTypeChange(option.value)}
                      clearable={false}
                      searchable={false}
                      disabled={disableCutTypeShape}
                      placeholder={disableCutTypeShape ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={3}
                      label="Rainage"
                      options={[
                        { value: false, label: 'Non' },
                        { value: true, label: 'Oui' },
                      ]}
                      name="creasing"
                      value={disableCreasing ? null : creasing}
                      onChange={(option: SelectOption<boolean>) =>
                        onCreasingChange((option.value as unknown) as string)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableCreasing}
                      placeholder={disableCreasing ? 'Non applicable' : ''}
                    />

                    {/*<SelectGroup
                          s={3}
                          label="BAT"
                            options= {[
                              { value: false, label: 'Non' },
                              { value: true, label: 'Oui' },
                            ]}
                            name= 'passedForPrint'
                            value= {passedForPrint}
                            onChange= {option => onPassedForPrintChange(option.value)}
                            clearable= {false}
                            searchable= {false}
                        />*/}
                  </div>

                  <div className="form-row">
                    <SelectGroup
                      s={4}
                      label="Lamination"
                      options={laminationOptions}
                      name="lamination"
                      value={requestData.laminationId || ''}
                      onChange={(option: SelectOption<string>) =>
                        onLaminationChange(option.value.length !== 0 ? option.value : null)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableLamination}
                      placeholder={disableLamination ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={4}
                      label="Oeillets"
                      options={eyeletOptions}
                      name="eyelet"
                      value={disableEyelet ? null : eyelet}
                      onChange={(option: SelectOption<EYELET_TYPES>) =>
                        onEyeletChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableEyelet}
                      placeholder={disableEyelet ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={4}
                      label="Perforation"
                      warning={warningsByProperty.perforation}
                      options={perforationOptions}
                      name="perforation"
                      value={disablePerforation ? null : perforation}
                      onChange={(option: SelectOption<PERFORATION_TYPES>) =>
                        onPerforationChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disablePerforation}
                      placeholder={disablePerforation ? 'Non applicable' : ''}
                    />
                  </div>

                  <OrderItemFields
                    requestData={requestData}
                    errorsByProperty={errorsByProperty}
                    warningsByProperty={warningsByProperty}
                    onCalculate={onCalculate}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 informations">
              <Card className="height100 calculator-product-informations">
                <div className="container">
                  <TechnicalInformation
                    requestData={requestData}
                    material={selectedMaterial}
                    technicalFileLink={`${CENTRAL_STATION_HOST}/documents/fiche-technique-rigide.pdf`}
                  />

                  <CostCardForm
                    productionMode={requestData.productionMode}
                    calculatorResult={result}
                    cartProps={cartProps}
                    isLoading={isLoading}
                    additionalWarningsElement={
                      Either.isRight(result) && (
                        <GlobalCalculateMessages
                          messages={getRemoteMessages(result.right.warnings || []).global}
                          manufacture={result.right.manufacture}
                          requestData={requestData}
                          className="warning"
                          isLoading={isLoading}
                        />
                      )
                    }
                  />
                </div>
                <CardActionFooter>
                  <ResetButton disabled={isLoading} reset={onReset} />

                  <AddToCartButton
                    disabled={!canAddToCart || isLoading}
                    isEditingItem={isEditingItem}
                    onAddToCart={() =>
                      onAddToCart({
                        orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
                        manualReference: requestData.manualReference,
                        description: requestData.description,
                        requestData,
                      })
                    }
                  />
                </CardActionFooter>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorForm;

function makeMaterialOptions(materials: CalculatorMaterial[], selectedMaterialType: string) {
  return materials
    .filter(material => material.type === selectedMaterialType)
    .map(material => {
      return {
        value: material.id,
        label: material.name,
        type: material.type,
      };
    });
}

//Détails laminations
/*
reference :
1 - Mate
2 - Brillante
3 - Spécial Sol
4 - Velleda
5 - Anti-graffiti
 */

//Détails matières avec velleda + anti-graf (pas de spécial sol pour le rigide)
/*
reference :
2 - PVC 3mm
6 - PVC 5 mm
3 - Dibond 3 mm


 */

function makeLaminationOptions(
  laminations: CalculatorLamination[],
  selectedMaterial: CalculatorMaterial
): SelectOption<string>[] {
  if (!selectedMaterial.lamination) {
    return [];
  }
  const generalLaminations = ['1', '2'];
  const wallLaminations = ['5', '4'];

  const materialReferencesForSpecificLaminations = ['2', '3', '6'];

  const laminationsRef = materialReferencesForSpecificLaminations.includes(
    selectedMaterial.reference
  )
    ? [...generalLaminations, ...wallLaminations]
    : generalLaminations;

  const validLaminations = laminations.filter(lamination =>
    laminationsRef.includes(lamination.reference)
  );
  return [
    {
      value: '',
      label: 'Aucune',
    },
    ...validLaminations.map(lamination => ({ value: lamination.id, label: lamination.name })),
  ];
}

const eyeletOptions = RIGID_EYELET_TYPES.map(type => ({
  value: type,
  label: RIGID_EYELET_TYPE_TRANSLATIONS[type],
}));

const perforationOptions = [
  { value: PERFORATION_TYPES.NONE, label: '-' },
  { value: PERFORATION_TYPES.UP, label: PERFORATION_TYPE_TRANSLATIONS[PERFORATION_TYPES.UP] },
  {
    value: PERFORATION_TYPES.FOUR_ANGLES,
    label: PERFORATION_TYPE_TRANSLATIONS[PERFORATION_TYPES.FOUR_ANGLES],
  },
  {
    value: PERFORATION_TYPES.UP_DOWN,
    label: PERFORATION_TYPE_TRANSLATIONS[PERFORATION_TYPES.UP_DOWN],
  },
  {
    value: PERFORATION_TYPES.LEFT_RIGHT,
    label: PERFORATION_TYPE_TRANSLATIONS[PERFORATION_TYPES.LEFT_RIGHT],
  },
];
