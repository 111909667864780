import * as React from 'react';
import { AuthenticationError } from '../errors/AuthenticationError';
import { InternalServerError } from '../errors/InternalServerError';
import { RouteComponentProps } from 'react-router';
import {generateToken} from '../actions';
import LoginForm from '../components/LoginForm';

export interface UserPostLoginInfo {
  resellerId: string;
  userId: string;
  token: string;
  resellerUserId: string;
}

interface LoginProps extends RouteComponentProps {
  onSuccessfulLogin: (result: UserPostLoginInfo) => void;
}

interface LoginState {
  isLoading?: boolean;
  error?: AuthenticationError | InternalServerError;
  mascarade?: { key: string; email: string };
}

interface FormElements {
  email: { value: string };
  password: { value: string };
}

class Login extends React.Component<LoginProps, LoginState> {
  state: LoginState = {
    isLoading: false,
    mascarade: { key: null, email: null },
  };

  constructor(props: LoginProps) {
    super(props);

    //Get url parameters to check if mascarade mode
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state.mascarade.key = params.get('mascaradeKey');
    this.state.mascarade.email = params.get('email');

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

    //Autologin for mascarade : inject email & password in form + trigger click
    if (this.state.mascarade.key) {
      const email = document.getElementById('email') as HTMLFormElement;
      email.value = this.state.mascarade.email;

      const password = document.getElementById('password') as HTMLFormElement;
      password.value = this.state.mascarade.key;

      const submitButton = document.getElementById('submitButton') as HTMLFormElement;
      submitButton.click();
    }
  }

  render() {
    return (
      <LoginForm
        isLoading={this.state.isLoading}
        onSubmit={this.handleSubmit}
        errorMessage={getErrorMessageForError(this.state.error)}
      />
    );
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({ ...this.state, isLoading: true });
    const elements = ((event.target as HTMLFormElement).elements as any) as FormElements;

    const email = elements.email.value;
    const password = elements.password.value;
    const mascaradeKey = this.state.mascarade.key;

    try {

      const user = await generateToken({ email, password, mascaradeKey });

      this.setState({ ...this.state, isLoading: false, error: null });
      this.props.onSuccessfulLogin(user);

      const mascaradeMode = mascaradeKey ? 'true' : 'false';
      localStorage.setItem('mascaradeMode', mascaradeMode);
      //Delete parameters in url
      this.props.history.push('/')

    } catch (error) {
      this.setState({
        isLoading: false,
        error,
      });
    }
  }
}

export default Login;

function getErrorMessageForError(error: Error): string | null {
  if (error instanceof AuthenticationError) {
    return `L'authentification a échoué`;
  }
  if (error instanceof InternalServerError) {
    return `Une erreur est survenue`;
  }

  return null;
}
