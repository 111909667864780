import * as React from 'react';
import {
  BUNDLE_TYPES,
  CALCULATORS,
  CALCULATORS_TRANSLATIONS,
  CUT_TYPES,
  CalculatorLamination,
  CalculatorMaterial,
  CalculatorOrderItem,
  EYELET_INTERVAL_TRANSLATIONS,
  EYELET_TYPES,
  FLEXIBLE_EYELET_TYPE_TRANSLATIONS,
  ModelInputFinishedProduct,
  ModelOrderItem,
  ORDER_ITEM_TYPES,
  PRINTING_TYPE_TRANSLATIONS,
  RIGID_EYELET_TYPE_TRANSLATIONS,
  assertNever,
} from '@oyp/shared-lib';
import { BUNDLE_TYPE_TRANSLATIONS } from '../../../calculator/translation';
import { Card, ShortenedName, formatter } from '@oyp/shared-components';
import {
  fetchMaterialForProduct,
  fetchOneFinishedProduct,
} from '../../../calculator/_modules/finished_product/actions';

const { formatCentimeter, formatPrice } = formatter;

interface UploadInfoColumnProps {
  orderItem: ModelOrderItem;
  laminations: CalculatorLamination[];
}

const UploadInfoColumn: React.FC<UploadInfoColumnProps> = props => {
  const { orderItem, laminations } = props;

  // TODO gérer saxoprint

  return (
    <Card title="INFORMATIONS" className="bg-primary-card-form file-upload-item-informations">
      {(() => {
        switch (orderItem.orderItemType) {
          case ORDER_ITEM_TYPES.CALCULATOR:
            return (
              <>
                {[CALCULATORS.FLEXIBLE, CALCULATORS.RIGID].includes(
                  orderItem.requestData.calculator
                ) && (
                  <FlexibleAndRigidDescriptionRows
                    orderItem={orderItem as CalculatorOrderItem}
                    laminations={laminations}
                  />
                )}

                {orderItem.requestData.calculator === CALCULATORS.FINISHED_PRODUCT && (
                  <FinishedProductDescriptionRows
                    orderItem={orderItem as CalculatorOrderItem}
                    laminations={laminations}
                  />
                )}
              </>
            );
          case ORDER_ITEM_TYPES.SAXOPRINT:
            return <>TODO</>;
          case ORDER_ITEM_TYPES.SMART_LABEL:
            return <>TODO</>;
        }
        assertNever(orderItem);
      })()}

      <DescriptionRow leftContent="Commentaire" rightContent={orderItem.internalComment || '-'} />

      <DescriptionRow
        leftContent="Votre prix HT"
        rightContent={formatPrice(orderItem.taxExcludedTotal)}
      />
    </Card>
  );
};

export default UploadInfoColumn;

interface DescriptionRowPros {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

const DescriptionRow: React.FC<DescriptionRowPros> = ({ leftContent, rightContent }) => (
  <div className="row pl-3 pr-3">
    <div className="col-sm-12 col-md-6 col-lg-6 text-left">{leftContent}</div>
    <div className="col-sm-12 col-md-6 col-lg-6 text-right font-weight-bold">{rightContent}</div>
  </div>
);

interface CalculatorUploadInfoColumnProps {
  orderItem: CalculatorOrderItem;
  laminations: CalculatorLamination[];
}

const FlexibleAndRigidDescriptionRows: React.FC<CalculatorUploadInfoColumnProps> = props => {
  const { orderItem, laminations } = props;
  const { requestData, label } = orderItem;
  const { eyelet, eyeletInterval, dimensions, cutType, quantity, printingType } = requestData;

  const lamination = laminations.find(l => l.id === requestData.laminationId);

  const eyeletTranslations =
    requestData.calculator === CALCULATORS.FLEXIBLE
      ? FLEXIBLE_EYELET_TYPE_TRANSLATIONS
      : RIGID_EYELET_TYPE_TRANSLATIONS;

  return (
    <>
      <DescriptionRow
        leftContent="Produit"
        rightContent={CALCULATORS_TRANSLATIONS[requestData.calculator]}
      />

      <DescriptionRow
        leftContent="Matière"
        rightContent={<ShortenedName name={label} maxNameLength={22} tooltipPlacement="top" />}
      />

      <DescriptionRow
        leftContent="Format"
        rightContent={`${formatCentimeter(dimensions.width)} x ${formatCentimeter(
          dimensions.height
        )}`}
      />

      <DescriptionRow leftContent="Quantité" rightContent={quantity} />

      <DescriptionRow
        leftContent="Impression"
        rightContent={PRINTING_TYPE_TRANSLATIONS[printingType]}
      />

      <DescriptionRow
        leftContent="Découpe"
        rightContent={cutType === CUT_TYPES.STRAIGHT ? 'Droite' : 'A la forme'}
      />

      <DescriptionRow leftContent="Lamination" rightContent={lamination ? lamination.name : '-'} />

      <DescriptionRow
        leftContent="Oeillets"
        rightContent={
          eyelet && eyelet !== EYELET_TYPES.NONE
            ? eyeletTranslations[eyelet] + ' ' + EYELET_INTERVAL_TRANSLATIONS[eyeletInterval]
            : '-'
        }
      />
    </>
  );
};

interface FinishedProductDescriptionRowsState {
  orderItemId: string;
  product?: ModelInputFinishedProduct;
  material?: CalculatorMaterial;
}

const FinishedProductDescriptionRows: React.FC<CalculatorUploadInfoColumnProps> = props => {
  const { orderItem } = props;
  const { requestData } = orderItem;
  const { quantity, imageryCount } = requestData;

  const initalState: FinishedProductDescriptionRowsState = {
    orderItemId: orderItem.id,
  };

  const [{ product, orderItemId, material }, setState] = React.useState(initalState);
  React.useEffect(() => {
    if (!product || orderItem.id !== orderItemId) {
      loadProductAndMaterial(
        requestData.finishedProductId,
        requestData.materialId,
        orderItem.id,
        setState
      );
    }
  });

  return (
    <>
      <DescriptionRow
        leftContent="Catégorie"
        rightContent={CALCULATORS_TRANSLATIONS[requestData.calculator]}
      />

      <DescriptionRow
        leftContent="Produit"
        rightContent={
          <ShortenedName
            name={product && product.label}
            maxNameLength={22}
            tooltipPlacement="top"
          />
        }
      />

      <DescriptionRow
        leftContent="Type"
        rightContent={BUNDLE_TYPE_TRANSLATIONS[requestData.bundleType]}
      />

      <DescriptionRow leftContent="Quantité" rightContent={quantity} />

      {requestData.bundleType !== BUNDLE_TYPES.STRUCTURE_ONLY && imageryCount > 1 && (
        <DescriptionRow leftContent="Amalgame" rightContent={`{imageryCount} visuel(s)`} />
      )}
      {material && (
        <DescriptionRow
          leftContent="Matière"
          rightContent={<ShortenedName name={material.name} maxNameLength={22} />}
        />
      )}
    </>
  );
};

async function loadProductAndMaterial(
  productId: string,
  materialId: string,
  orderItemId: string,
  setState: (state: FinishedProductDescriptionRowsState) => void
) {
  const product: ModelInputFinishedProduct = await fetchOneFinishedProduct(productId);

  const material = materialId ? await fetchMaterialForProduct(product, materialId) : null;

  setState({
    product,
    material,
    orderItemId,
  });
}
