"use strict";

var _defineProperty = require("/var/www/vhosts/preprod.oyp.fr/deploy_tools/apps/demo-calm-panda/releases/20210420162956/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var objects_1 = require("../../utils/objects");

function isInitialRequestData(initialRequestData, requestData) {
  return objects_1.matchObjectToSourceObjectRecursive(initialRequestData, requestData);
}

exports.isInitialRequestData = isInitialRequestData;

function getDefaultMaterialForType(materials, filterType) {
  return materials.find(function (material) {
    return material.type === filterType;
  });
}

exports.getDefaultMaterialForType = getDefaultMaterialForType;

function getSelectedMaterialType(materialTypes) {
  return (materialTypes.find(function (matType) {
    return matType.isActive;
  }) || {}).type;
}

exports.getSelectedMaterialType = getSelectedMaterialType;

exports.getUpdateRequestDataFromItemId = function (propertyName, requestData, items, onCalculate) {
  return function (id) {
    return onCalculate(_objectSpread({}, requestData, _defineProperty({}, propertyName, items.find(function (item) {
      return item.id === id;
    }))));
  };
};