import {
  CALCULATORS,
  CUT_TYPES,
  CalculatorRequestData,
  CalculatorRigidFormPermanentResources,
  EYELET_TYPES,
  MATERIAL_TYPE_TRANSLATIONS,
  PERFORATION_TYPES,
  PRINTING_TYPES,
  PRODUCTION_MODES,
  TYPE_AKILUX,
  TYPE_CARDBOARD,
  TYPE_DIBOND,
  TYPE_PLEXI,
  TYPE_RIGID_PVC,
  WHITE_LAYER_TYPES,
} from '@oyp/shared-lib';
import { fetchLaminations, fetchRigidMaterials } from '../../actions';
import { getDefaultMaterialForType } from '../../toolbox';
import { getSelectedMaterialType } from '@oyp/shared-components';

export const RIGID_INITIAL_TYPE = TYPE_AKILUX;

export async function fetchRigidPermanentResources(): Promise<
  CalculatorRigidFormPermanentResources
> {
  const [materials, laminations] = await Promise.all([
    fetchRigidMaterials({ sort: 'rank', isActive: true }),
    fetchLaminations({ sort: 'rank', isActive: true }),
  ]);

  const materialTypes = materialBaseTypes.map(type => ({
    ...type,
    isActive: type.type === RIGID_INITIAL_TYPE,
  }));

  return {
    materials,
    laminations,
    materialTypes,
  };
}

export function getRigidInitialRequestData(
  permanentFormResources: CalculatorRigidFormPermanentResources
): CalculatorRequestData {
  return {
    ...baseRequestData,
    materialId: getDefaultMaterialForType(
      permanentFormResources.materials,
      getSelectedMaterialType(permanentFormResources.materialTypes)
    ).id,
  };
}

export const baseRequestData: CalculatorRequestData = {
  calculator: CALCULATORS.RIGID,
  dimensions: {
    width: 0,
    height: 0,
  },
  quantity: 1,
  cutType: CUT_TYPES.STRAIGHT,
  productionMode: PRODUCTION_MODES.STANDARD,
  printingType: PRINTING_TYPES.ONE_SIDED,
  whiteLayerType: WHITE_LAYER_TYPES.NONE,
  eyelet: EYELET_TYPES.NONE,
  perforation: PERFORATION_TYPES.NONE,
  creasing: false,
  imageryCount: 1,
  description: '',
  manualReference: '',
};

const materialBaseTypes = [
  {
    label: MATERIAL_TYPE_TRANSLATIONS[TYPE_AKILUX],
    type: TYPE_AKILUX,
    formKey: 'akilux',
  },
  {
    label: MATERIAL_TYPE_TRANSLATIONS[TYPE_CARDBOARD],
    type: TYPE_CARDBOARD,
    formKey: 'carton',
  },
  {
    label: MATERIAL_TYPE_TRANSLATIONS[TYPE_DIBOND],
    type: TYPE_DIBOND,
    formKey: 'dibond',
  },
  { label: MATERIAL_TYPE_TRANSLATIONS[TYPE_RIGID_PVC], type: TYPE_RIGID_PVC, formKey: 'pvc' },
  {
    label: MATERIAL_TYPE_TRANSLATIONS[TYPE_PLEXI],
    type: TYPE_PLEXI,
    formKey: 'plexi',
  },
];
