"use strict"; // Unifier avec shared-lib/validator

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.required = function () {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return value || value === 0 ? undefined : 'Requis';
};

exports.maxLength = function (max) {
  return function () {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    return value && value.length > max ? "Maximum ".concat(max, " caract\xE8res") : undefined;
  };
};

exports.minLength = function (min) {
  return function () {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    return !value || value.length < min ? "Minimum ".concat(min, " caract\xE8res") : undefined;
  };
};

exports.isEmail = function (value) {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Format email invalide' : undefined;
};

exports.isPhoneNumber = function (value) {
  return value && !/^\+[0-9]*$|^0[0-9]{9}$/.test(value) ? 'Format numéro de téléphone invalide' : undefined;
};

exports.isName = function (value) {
  return value && /[^a-zA-Z-']/.test(value) ? 'Ni chiffres ni caractères spéciaux' : undefined;
};

exports.isPositive = function (value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return undefined;
  }

  return typeof value === 'number' && !isNaN(value) && value >= 0 ? undefined : "Cette valeur doit \xEAtre positive";
};