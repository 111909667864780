import {
  BUNDLE_TYPES,
  CALCULATORS,
  CalculatorFinishedProductFormPermanentResources,
  CalculatorMaterial,
  CalculatorRequestData,
  FINISHED_PRODUCT_CATEGORIES,
  FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS,
  ModelFinishedProductWithFiles,
  ModelInputFinishedProduct,
  ModelPrint,
  ORDER_ITEM_TYPES,
  PRODUCTION_MODES,
} from '@oyp/shared-lib';
import { fetchAvailableFinishedProducts, fetchFinishedProductFiles } from './actions';
import { fetchFlexibleMaterials, fetchRigidMaterials } from '../../actions';

export const FINISHED_PRODUCT_INITIAL_TYPE = FINISHED_PRODUCT_CATEGORIES.ROLLUP;

interface FinishedProductRequestData extends CalculatorRequestData {
  calculator: CALCULATORS;
  quantity: number;
  imageryCount: number;
  productionMode: PRODUCTION_MODES;
  bundleType: BUNDLE_TYPES;
  finishedProductId: string;
  materialId: string;
  material?: CalculatorMaterial;
  [key: string]: any;
}

export async function fetchFinishedProductPermanentResources(): Promise<
  CalculatorFinishedProductFormPermanentResources
> {
  const [products, flexibleMaterials, rigidMaterials] = await Promise.all([
    fetchAvailableFinishedProducts({ sort: 'rank', isActive: true }),
    fetchFlexibleMaterials({ sort: 'rank', isActive: true }),
    fetchRigidMaterials({ sort: 'rank', isActive: true }),
  ]);

  const productsWithFiles = await Promise.all(products.map(getProductWithFiles));

  const firstAvailableProduct = products.find(product => !product.isOutOfStock);
  const initialType =
    firstAvailableProduct &&
    !products.some(
      product => !product.isOutOfStock && product.category === FINISHED_PRODUCT_INITIAL_TYPE
    )
      ? firstAvailableProduct.category
      : FINISHED_PRODUCT_INITIAL_TYPE;

  return {
    products: productsWithFiles,
    flexibleMaterials,
    rigidMaterials,
    materialTypes: baseMaterialTypes.map(type => ({
      ...type,
      isActive: type.type === initialType,
      disabled: !products.some(product => product.category === type.type && !product.isOutOfStock),
    })),
  };
}

export function getFinishedProductInitialRequestData(
  permanentFormResources: CalculatorFinishedProductFormPermanentResources
): FinishedProductRequestData {
  const { materialTypes, products, flexibleMaterials, rigidMaterials } = permanentFormResources;

  const initialProduct = products.filter(
    product => product.category === materialTypes.find(category => category.isActive).type
  )[0];

  const initialMaterial = getDefaultMaterialForPrint(
    initialProduct.print,
    flexibleMaterials,
    rigidMaterials
  );
  const bundleType = getDefaultBundleTypeForProduct(initialProduct);

  return {
    orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
    ...baseRequestData,
    product: initialProduct,
    material: initialMaterial,
    bundleType,
    finishedProductId: initialProduct.id,
    materialId: initialMaterial ? initialMaterial.id : null,
  };
}

export const baseRequestData = {
  calculator: CALCULATORS.FINISHED_PRODUCT,
  quantity: 1,
  productionMode: PRODUCTION_MODES.STANDARD,
  imageryCount: 1,
  description: '',
  manualReference: '',
};

export function getMaterialsForFinishedProductWithPrint(
  flexibleMaterials: CalculatorMaterial[],
  rigidMaterials: CalculatorMaterial[],
  print: ModelPrint
): CalculatorMaterial[] {
  const materials = print.calculator === CALCULATORS.FLEXIBLE ? flexibleMaterials : rigidMaterials;

  return materials.filter(material => print.materialIds.includes(material.id));
}

export function getDefaultBundleTypeForProduct(product: ModelInputFinishedProduct): BUNDLE_TYPES {
  if (product.printId && product.structureId) {
    return BUNDLE_TYPES.FULL;
  } else if (product.printId) {
    return BUNDLE_TYPES.MATERIAL_ONLY;
  }

  return BUNDLE_TYPES.STRUCTURE_ONLY;
}

export function getDefaultMaterialForPrint(
  print: ModelPrint | null,
  flexibleMaterials: CalculatorMaterial[],
  rigidMaterials: CalculatorMaterial[]
): CalculatorMaterial | null {
  if (!print) {
    return null;
  }

  const materialsToBeUsed = print
    ? getMaterialsForFinishedProductWithPrint(flexibleMaterials, rigidMaterials, print)
    : [];

  return materialsToBeUsed.length > 0
    ? materialsToBeUsed.find(material => material.id === print.materialIds[0])
    : null;
}

const baseMaterialTypes = [
  {
    label: FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS[FINISHED_PRODUCT_CATEGORIES.ROLLUP],
    type: FINISHED_PRODUCT_CATEGORIES.ROLLUP,
    formKey: 'rollup',
  },
  {
    label: FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS[FINISHED_PRODUCT_CATEGORIES.XBANNER],
    type: FINISHED_PRODUCT_CATEGORIES.XBANNER,
    formKey: 'xbanner',
  },
  {
    label: FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS[FINISHED_PRODUCT_CATEGORIES.OUTDOOR],
    type: FINISHED_PRODUCT_CATEGORIES.OUTDOOR,
    formKey: 'outdoor',
  },
];

async function getProductWithFiles(
  product: ModelInputFinishedProduct
): Promise<ModelFinishedProductWithFiles> {
  const files = await fetchFinishedProductFiles(product.id);

  return {
    ...product,
    files,
  };
}
