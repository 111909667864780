import { MDBIcon } from 'mdbreact';
import { PRIMARY_COLOR_DARKER, PRIMARY_COLOR_LIGHTER } from '../../style_variables';
import { TooltipContainer } from '@oyp/shared-components';
import Changelog from './Changelog';
import ExternalLink from '../ExternalLink';
import React from 'react';
import raw from 'raw.macro';
import styled from 'styled-components';

const ChangelogText = raw('../../CHANGELOG.md');

const versionNumber = ChangelogText.split('\n')[0].split('# Version ')[1];

interface FooterProps {
  isAuthenticated?: boolean;
}

const Footer: React.FC<FooterProps> = props => (
  <StyledFooter>
    <div>
      PrintCenter | &copy; 2019 - {new Date().getFullYear()} Inprint | Support commercial (
      <ExternalLink href="tel:+33561246535">05 61 24 65 35</ExternalLink>
      &nbsp;- &nbsp;
      <ExternalLink href="mailto:contact@in-print.net">contact@in-print.net</ExternalLink>) |
      Support informatique (<ExternalLink href="tel:+335531618257">05 31 61 82 57</ExternalLink>
      &nbsp;- &nbsp;
      <ExternalLink href="mailto:contact@in-print.net">contact@in-print.net</ExternalLink>)
      {props.isAuthenticated && (
        <>
          {' | '}
          <Changelog content={ChangelogText} versionNumber={versionNumber} />
        </>
      )}
      | &nbsp;
      <ExternalLink href="/document/documentation-printcenter.pdf">
        <TooltipContainer tooltipContent="Lire la documentation" tooltipLook="dark">
          <StyledIconContainer>
            <MDBIcon icon="book" />
          </StyledIconContainer>
        </TooltipContainer>{' '}
      </ExternalLink>
    </div>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: ${PRIMARY_COLOR_LIGHTER};
  color: #fff;
  font-size: 14px;
  padding: 5px 0;
  z-index: 9999;

  > div {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a,
  a:visited {
    color: ${PRIMARY_COLOR_DARKER};

    &:hover {
      color: ${PRIMARY_COLOR_DARKER};
    }
  }
`;

const StyledIconContainer = styled.span`
  color: #ffffff;
`;
